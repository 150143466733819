import React from 'react';
import { connect } from 'react-redux';
import { updateUser, getUserById, registrarUsuarioNuevo, clearUsuario, getPlanes, AssignPlan } from 'actions';
import _ from 'lodash';
//import UploadImages from './UploadImages.jsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Loader from 'assets/img/loadern.gif';
import { push } from 'react-router-redux';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import moment from 'moment';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
	label,
} from 'reactstrap';

const initialState = {
	usuario: {
		_id: '',
		name: '',
		type: '',
		state: false,
		avatar: '',
		age: '',
		gender: '',
		activePlan: '',
		celphone: '',
		password: '',
		housePhone: '',
		facebook: '',
		email: '',
		address: '',
		purchases: '',
		dateNextControl: '',
		paymentState: '',
		instagrame: '',
		whatsApp: '',
		workHours: '',
		daysWorks: '',
		typeFeedin: '',
		objectives: '',
		phisicsProblems: '',
		operations: '',
		diseases: '',
		pregnancies: '',
		medicines: '',
		medicalNotes: '',
		physicalActivity: '',
		sports: '',
		sportsHours: '',
		sportsDays: '',
	},
	planes: [],
	planSeleccionado: { title: '', value: '' },
	alert: null,
	imagenes: '',
	msg: '',
};

class editUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			usuario: {
				_id: '',
				name: '',
				type: '',
				state: false,
				avatar: '',
				age: '',
				gender: '',
				celphone: '',
				password: '',
				housePhone: '',
				activePlan: '',
				facebook: '',
				email: '',
				address: '',
				purchases: '',
				dateNextControl: '',
				paymentState: '',
				instagrame: '',
				whatsApp: '',
				workHours: '',
				daysWorks: '',
				typeFeedin: '',
				objectives: '',
				phisicsProblems: '',
				operations: '',
				diseases: '',
				pregnancies: '',
				medicines: '',
				medicalNotes: '',
				physicalActivity: '',
				sports: '',
				sportsHours: '',
				sportsDays: '',
			},
			planes: [],
			planSeleccionado: { label: '', value: '' },
			imagenes: '',
			msg: '',
			alert: null,
		};
	}

	componentWillUnmount() {
		document.body.classList.toggle('nuevo-usuarios-component');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.usuario !== prevState.usuario) {
			return {
				usuario: prevState.usuario,
			};
		}
		if (nextProps.planes !== prevState.planes) {
			return {
				planes: prevState.planes,
			};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.usuario !== this.props.usuario) {
			this.setState({
				selectedFiles: null,
				usuario: this.props.usuario,
			});
		}
		if (prevProps.planes !== this.props.planes) {
			this.setState({
				planes: this.props.planes,
			});
		}
	}

	componentDidMount() {
		if (_.isEmpty(this.state.planes)) {
			this.props.getPlanes();
		}

		if (_.isEmpty(this.props.usuario)) {
			const id = this.props.match.params.id;
			if (id) {
				this.props.getUserById(id);
				return;
			}
		}

		if (!this.props.match.params.id) {
			this.props.clearUsuario();
			this.setState(initialState);
			return;
		}

		this.setState({
			selectedFiles: null,
			usuario: this.props.usuario,
		});
	}

	selectedPlan = (event) => {
		this.setState({
			alert: (
				<ReactBSAlert
					style={{ display: 'block', marginTop: '-300px', height: '450px' }}
					title="Agregar Plan Manualmente "
					onCancel={() => this.hideAlert()}
					onConfirm={(e) => this.addPlanManual()}
					showCancel={true}
					showConfirm={true}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="danger"
					showCloseButton
				>
					<h4 style={{ color: '#000' }}>
						<b>habilitar Plan:</b>
					</h4>
					<h6 style={{ color: '#000' }}>
						Despues que le habilites el plan le llegara un mail al usuario con un link para que complete la
						Anamnesis y el primer control tambien se le asignara el Plan y se agendaran los controles
						correspondientes automaticamente
					</h6>
					<>
						<Form className="form">
							<Select
								className="react-select success"
								classNamePrefix="react-select"
								name="planSeleccionado"
								value={this.state?.planSeleccionado}
								onChange={(value) => this.handlerSelectPlan(value, 'label')}
								options={this.props.planes.map((plan) => ({ value: plan.title, label: plan.title }))}
								placeholder="Planes"
							/>
						</Form>
					</>
				</ReactBSAlert>
			),
		});
	};

	addPlanManual = (e) => {
		const planSel = this.state.planes.find((plan) => plan.title === this.state.planSeleccionado.label);
		const data = {
			productoId: planSel._id,
			producto: planSel.title,
			typeProduct: 'plan',
			controles: planSel.queries,
			monto: planSel.costPesos,
			userId: this.props.usuario._id,
			userEmail: this.props.usuario.email,
		};
		console.log(data);
		this.props.AssignPlan(data);

		this.setState({
			alert: null,
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	handlerAgregarControl = () => {
		this.props.handlerAgregarControl(this.props.usuario._id);
	};

	handlerSelect = (value, type) => {
		const toChange = value.value;
		const { usuario } = this.state;
		usuario[type] = toChange;
		this.setState({ ...this.state, usuario });
	};

	handlerSelectPlan = (value, type) => {
		const toChange = value.value;
		const { planSeleccionado } = this.state;
		planSeleccionado[type] = toChange;
		this.setState({ ...this.state, planSeleccionado });
	};

	handleInput = (event) => {
		const { usuario } = this.state;
		usuario[event.target.name] = event.target.value;
		this.setState({ usuario });
	};

	//ES PARA IMAGEN
	onDrop = (acceptedFiles) => {
		let files = [];
		let st = [];
		if (!this.state.selectedFiles) {
			st = acceptedFiles;
		} else {
			files = this.state.selectedFiles;
			st = _.concat(files, acceptedFiles);
		}
		console.log(st);
		this.setState({
			selectedFiles: st,
		});

		this.handlerSend();
	};

	removeImageHandler = (e, x, whereIs) => {
		if (whereIs === 'images') {
			const images = this.state.usuario.avatar !== '' ? _.split(this.state.usuario.avatar, ',') : [];
			const imagenesFiltered = _.map(images, (m) => {
				if (m !== x) {
					return m;
				}
			});
			const filtered = _.compact(_.map(imagenesFiltered));
			this.removeFile(x); // this in case you prefer upload before
			const nuevo_usuario = { ...this.state.usuario, avatar: filtered };
			this.setState({ ...this.state, usuario: nuevo_usuario });
		} else {
			const { selectedFiles } = this.state;
			const selectedFiltered = _.filter(selectedFiles, (s) => s !== x);
			this.setState({ ...this.state, selectedFiles: selectedFiltered });
		}
	};

	removeFile = (fileName) => {
		if (fileName) {
			axios
				.post(
					'https://calendar-apirest.herokuapp.com/delete-file',
					{ fileName: fileName },
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					}
				)
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							console.log(response.data.error);
						} else {
							// Success
							console.log('filedata removed', fileName);
							this.handlerSend();
						}
					}
				})
				.catch((error) => {
					// If another error
					console.log(error);
				});
		} else {
			// if file not selected throw error
			console.log('Send a fileName to remove');
		}
	};

	singleFileChangedHandler = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	multipleFileChangedHandler = (files) => {
		this.setState({
			selectedFiles: files,
		});
	};

	verAnamnesis(idAnamnesis) {
		console.log('Ver' + idAnamnesis);
	}

	removeAnamnesis(idAnamnesis) {
		console.log('remove' + idAnamnesis);
	}

	showtheImages = () => {
		const images = this.state.usuario.avatar !== '' ? _.split(this.state.usuario.avatar, ',') : [];
		let oldImages = null;
		oldImages = _.map(images, (x) => {
			if (x !== '' && x !== 'undefined') {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						{this.props.loading ? (
							''
						) : (
							<Button
								className="btn-icon btn-round"
								color="pinterest"
								onClick={(e) => {
									this.removeImageHandler(e, x, 'images');
								}}
							>
								<i style={{ fontSize: '20px' }} className="tim-icons icon-simple-remove" />
							</Button>
						)}
						<img
							alt=""
							width="140px"
							src={'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/' + x}
						></img>
					</div>
				);
				return img;
			} else return null;
		});
		return <div>{oldImages}</div>;
	};

	handlerSend = () => {
		const data = new FormData();
		const id = this.props.usuario ? this.props.usuario._id : null;
		const { usuario, selectedFiles } = this.state;

		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
			}
		}
		if (id) {
			data.append('_id', usuario._id);
		} else {
			usuario.password && data.append('password', usuario.password);
		}
		data.append('name', usuario.name);
		usuario.type && data.append('type', usuario.type);
		usuario.state && data.append('state', usuario.state);
		usuario.avatar && data.append('avatar', usuario.avatar);
		usuario.age && data.append('age', usuario.age);
		usuario.gender && data.append('gender', usuario.gender);
		usuario.celphone && data.append('celphone', usuario.celphone);
		usuario.housePhone && data.append('housePhone', usuario.housePhone);
		usuario.facebook && data.append('facebook', usuario.facebook);
		usuario.activePlan && data.append('activePlan', usuario.activePlan);
		usuario.email && data.append('email', usuario.email);
		usuario.address && data.append('address', usuario.address);
		usuario.purchases && data.append('purchases', usuario.purchases);
		usuario.dateNextControl && data.append('dateNextControl', usuario.dateNextControl);
		usuario.paymentState && data.append('paymentState', usuario.paymentState);
		usuario.instagrame && data.append('instagrame', usuario.instagrame);
		usuario.whatsApp && data.append('whatsApp', usuario.whatsApp);
		const validUsuarioNuevo = usuario.name && usuario.email && usuario.password;
		const validEditUsuario = usuario.name && usuario.email;

		if (id) {
			if (validEditUsuario) {
				this.props.updateEditedUser(data, id);
			} else {
				this.notify('br', 'requiere *Nombre y *Email');
			}
		} else {
			if (validUsuarioNuevo) {
				this.props.registrarUsuarioNuevo(data);
			} else {
				this.notify('br', 'Usuario nuevo requiere *Nombre *Email *Password');
			}
		}
	};
	componentUploadImage = () => {
		const maxSize = 3000000;
		const component = (
			<div>
				<Dropzone onDrop={this.onDrop} accept="image/png, image/jpeg" minSize={0} maxSize={maxSize}>
					{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
						const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
						return (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div
									style={{
										width: '100%',
										height: '200px',
										border: '5px dashed rgb(87, 169, 125)',
										marginBottom: '30px',
										textAlign: 'center',
										color: 'rgb(87, 169, 125)',
									}}
								>
									{this.props.loading ? (
										<img alt="..." style={{ marginTop: '30px' }} src={Loader} width={'70px'} />
									) : (
										<i
											style={{ fontSize: '60px', marginTop: '30px' }}
											className="icon-line2-picture"
										/>
									)}
									<h6 style={{ marginTop: '20px' }}>
										{!isDragActive && 'Click aqui o arrastra y suelta tus imagenes'}
										{isDragActive && !isDragReject && 'Sueltalo como si te estuviera quemando!'}
										{isDragReject && 'Este tipo de formato no lo aceptamos! JPG o PNG '}
									</h6>
									{isFileTooLarge && (
										<div className="text-danger mt-2">Imagen demasiado pesada max 3Mb</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<div>
					<h6>Imagenes Perfil</h6>
				</div>
				<div>{this.state.usuario ? this.showtheImages() : ''}</div>
			</div>
		);
		return component;
	};

	notify = (place, message) => {
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b></b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handlerCoso(value) {
		this.inputConfirmAlert(value);
	}

	inputConfirmAlert = (value) => {
		setTimeout(this.inputConfirmAlertNext(value), 200);
	};

	inputConfirmAlertNext = (value) => {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="success"
					btnSize=""
					title="Control agendado para: "
				>
					<b>{value}</b>
				</ReactBSAlert>
			),
		});
	};

	handlerEnviarMensaje() {
		console.log('#Mensaje');
	}

	handlerNuevoAnamnesisOffline() {
		this.props.newAnamnesis(this.props.usuario._id);
	}
	handlerNuevoAnamnesisOnline() {
		this.props.newAnamnesisOnline(this.props.usuario._id);
	}

	handlerNuevoControl() {
		console.log(this.props.usuario._id);
		this.props.newControl(this.props.usuario._id);
	}

	editControl(idControl) {
		this.props.editControl(idControl);
	}

	editAnamnesis(idAnamnesis) {
		this.props.editAnamnesis(idAnamnesis);
	}

	handlerAsignarPlan() {
		console.log('asignar Plan ' + this.props.usuario._id);
		console.log(this.state.planes);
		this.selectedPlan();
	}

	getActivePlan(){
		const activePlan = this.props?.planes?.find((plan)=> plan.title === this.props.usuario.activePlan);
		return activePlan?.anamnesis;
	}


	render() {
		const { id: idUsuario } = this.props.match.params;
		//const anamnesisCompleted = localStorage.getItem("anamnesisCompleted");
		//const firstControlCompleted = localStorage.getItem("firstControlCompleted");

		this.getActivePlan();
		console.log(this.state.planSeleccionado);
		return this.state.loading || !this.state.usuario ? (
			<div style={{ width: '100%', textAlign: 'center' }}>
				<img src={Loader} width="50px" alt="" />
				loading...
			</div>
		) : (
			<>
				{this.state.alert}
				<Container>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: 'rgb(87, 169, 125)' }}>
									{idUsuario ? `Perfil de ${this.state.usuario.name}` : 'Nuevo Usuario'}
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md={idUsuario ? '9' : '12'}>
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Row>
											<Col className="mr-auto ml-auto" md="12" style={{ padding: '20px' }}>
												{this.componentUploadImage()}
											</Col>
										</Row>
										<Form className="form">
											<Row md="12">
												<Col className="mr-auto ml-auto" md="12">
													<label>Nombre</label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-user text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Nombre completo"
															name="name"
															type="text"
															value={this.state.usuario.name}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													{this.props.user.type === 'admin' && (
														<>
															<label>Tipo Usuario</label>
															<InputGroup>
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i
																			className="icon-line2-users text-success"
																			style={{ fontSize: '16px' }}
																		/>
																	</InputGroupText>
																</InputGroupAddon>
																<Input
																	placeholder="Tipo Usuario"
																	name="type"
																	type="text"
																	value={this.state.usuario.type}
																	onChange={this.handleInput}
																	style={{ height: '45px' }}
																/>
															</InputGroup>
														</>
													)}
													{this.props.user.type === 'admin' && (
														<>
															<label>Estado Usuario</label>
															<Select
																className="react-select success"
																classNamePrefix="react-select"
																name="state"
																value={{
																	value: this.state.usuario.state,
																	label: this.state.usuario.state,
																}}
																onChange={(value) => this.handlerSelect(value, 'state')}
																options={[
																	{ value: 'Activo', label: 'Activo' },
																	{ value: 'Desactivo', label: 'Desactivo' },
																	{ value: 'Baniado', label: 'Baniado' },
																]}
																placeholder="Genero"
															/>
														</>
													)}
													{this.props.user.type === 'admin' && (
														<>
															<label>Contraseña</label>
															<InputGroup>
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i
																			className="icon-lock1 text-success"
																			style={{ fontSize: '16px' }}
																		/>
																	</InputGroupText>
																</InputGroupAddon>
																<Input
																	placeholder="Password"
																	name="password"
																	type="password"
																	disabled={idUsuario}
																	value={this.state.usuario.password}
																	onChange={this.handleInput}
																	style={{ height: '45px' }}
																/>
															</InputGroup>
														</>
													)}
													<label>Edad</label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-grin-beam-sweat1 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Edad"
															name="age"
															type="number"
															min="0"
															value={this.state.usuario.age || ''}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<label>Genero</label>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="gender"
														value={
															{
																value: this.state.usuario.gender,
																label: this.state.usuario.gender,
															} || ''
														}
														onChange={(value) => this.handlerSelect(value, 'gender')}
														options={[
															{ value: 'Masculino', label: 'Masculino' },
															{ value: 'Femenino', label: 'Femenino' },
															{ value: 'Otro', label: 'Otro' },
														]}
														placeholder="Genero"
													/>
													<label>Celular</label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-screen-smartphone text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Celular"
															name="celPhone"
															type="number"
															value={this.state.usuario.celPhone || ''}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<label>Telefono fijo</label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-phone3 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Telefono Casa"
															name="housePhone"
															type="number"
															value={this.state.usuario.housePhone}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<label>Email</label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-envelope text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Email"
															name="email"
															type="text"
															disabled={idUsuario}
															value={this.state.usuario.email}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<label>Direccion</label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-pointer text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Direccion"
															name="address"
															type="text"
															value={this.state.usuario.address}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<label>Plan Activo</label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-checkbox-checked text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Plan activo"
															name="activePlan"
															type="text"
															disabled={this.props.user.type === 'admin' ? false : true}
															value={this.state.usuario.activePlan}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													{this.props.user.type === 'admin' && (
														<>
															<label>Id de productos comprados </label>
															<InputGroup>
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i
																			className="icon-line2-bag text-success"
																			style={{ fontSize: '16px' }}
																		/>
																	</InputGroupText>
																</InputGroupAddon>
																<Input
																	placeholder="Compras"
																	name="purchases"
																	type="text"
																	value={this.state.usuario.purchases}
																	onChange={this.handleInput}
																	style={{ height: '45px' }}
																/>
															</InputGroup>
														</>
													)}
													{this.props.user.type === 'admin' && (
														<>
															<label>Fecha proximo control </label>
															<InputGroup>
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i
																			className="icon-line2-note text-success"
																			style={{ fontSize: '16px' }}
																		/>
																	</InputGroupText>
																</InputGroupAddon>
																<Input
																	placeholder="Proximo Control"
																	name="dateNextControl"
																	type="text"
																	value={this.state.usuario.dateNextControl}
																	onChange={this.handleInput}
																	style={{ height: '45px' }}
																/>
															</InputGroup>
														</>
													)}
													{this.props.user.type === 'admin' && (
														<>
															<label>Estado del pago </label>
															<Select
																className="react-select success"
																classNamePrefix="react-select"
																name="paymentState"
																value={{
																	value: this.state.usuario.paymentState,
																	label: this.state.usuario.paymentState,
																}}
																onChange={(value) =>
																	this.handlerSelect(value, 'paymentState')
																}
																options={[
																	{ value: 'Pago', label: 'Pago' },
																	{ value: 'No pago', label: 'No pago' },
																]}
																placeholder="Genero"
															/>
														</>
													)}
													<label>Facebook </label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-social-facebook text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Facebook"
															name="facebook"
															type="text"
															value={this.state.usuario.facebook}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<label>Instagram </label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-instagram text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Instagram"
															name="instagrame"
															type="text"
															value={this.state.usuario.instagrame}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<label>WhatsApp </label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-whatsapp text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="WhatsApp"
															name="whatsApp"
															type="text"
															value={this.state.usuario.whatsApp}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
												</Col>
											</Row>
										</Form>
									</CardBody>
									<CardFooter style={{ textAlign: 'center' }}>
										<Button
											className="btn-round"
											color="warning"
											style={{ width: '300px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											{idUsuario ? 'Actualizar Perfil' : 'Crear Usuario'}
										</Button>
									</CardFooter>
								</Card>
							</Col>
							<Col className="mr-auto" md="3">
							  { !_.isEmpty(this.props.usuario.controls) &&
											<Card className="card-register card-black" style={{ marginBottom: '15px', backgroundColor:'rgb(86 167 123)' }}>
												<CardBody style={{textAlign:'center'}}>
													<h4><i className='icon-calendar3' /> Proximos Controles </h4>
													{this.props.usuario.controls.map((control) => {
														return moment(control).isAfter(moment()) && <h4><i className='icon-child'/> {moment(control).format('l')}</h4>
													})}
												</CardBody>
											</Card>
								}
								{idUsuario && (
									<>
										<Button
											className="btn-round"
											style={{ width: '100%', paddingLeft: '20px' }}
											color="warning"
											href="https://www.nutricionconscienteuy.com/auth/turnos-presenciales"
											size="lg"
										>
											<i className="icon-calendar-alt" /> Agendar Turno presencial
										</Button>
										{this.props.usuario.activePlan !== 'none' ||
										this.props.user.type === 'admin' ? (
											<>
												<Button
													className="btn-round"
													style={{ width: '100%', paddingLeft: '20px' }}
													color="warning"
													href={this.getActivePlan() ==='Extendido'? `/auth/nueva-ficha-anamnesis/${this.props.usuario._id}` : `/auth/nueva-ficha-anamnesis-online/${this.props.usuario._id}`}
													size="lg"
												>
													<i className="icon-clipboard-list" /> Datos de Anamnesis
												</Button>
												<Button
													className="btn-round"
													style={{ width: '100%', paddingLeft: '20px' }}
													color="warning"
													onClick={(e) => {
														this.handlerAgregarControl();
													}}
													size="lg"
												>
													<i className="icon-calendar-check" /> Listado Controles
												</Button>
											</>
										) : (
											[]
										)}
										
										{this.props.user.type === 'admin' && (
											<>
												<Button
													className="btn-round"
													style={{ width: '100%', paddingLeft: '20px' }}
													color="warning"
													onClick={(e) => {
														this.handlerAsignarPlan();
													}}
													size="lg"
												>
													<i className="icon-folder-check" /> Asignar Plan
												</Button>
											</>
										)}
										
									</>
								)}
							</Col>
						</Row>
						<Row></Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEditedUser: (data, id) => dispatch(updateUser(data, id)),
		getUserById: (id) => dispatch(getUserById(id)),
		registrarUsuarioNuevo: (data) => dispatch(registrarUsuarioNuevo(data)),
		clearUsuario: () => dispatch(clearUsuario()),
		newAnamnesis: (id) => dispatch(push(`/auth/nueva-ficha-anamnesis/${id}`)),
		newAnamnesisOnline: (id) => dispatch(push(`/auth/nueva-ficha-anamnesis-online/${id}`)),
		editAnamnesis: (idAnamnesis) => dispatch(push(`/auth/anamnesis/${idAnamnesis}`)),
		newControl: (id) => dispatch(push(`/auth/nueva-ficha-control/${id}`)),
		editControl: (idControl) => dispatch(push(`/auth/control/${idControl}`)),
		handlerAgregarControl: (id) => dispatch(push(`/auth/controles/${id}`)),
		getPlanes: () => dispatch(getPlanes()),
		AssignPlan: data => dispatch(AssignPlan(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(editUser);
