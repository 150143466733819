import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { getPlanById } from 'actions';
import YouTube from 'react-youtube';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  CardTitle, ListGroup
} from "reactstrap";

import ButtonMercadoPago from 'components/ButtonMercadoPago';

const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

class TurnosPresenciales extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			plan: {
				userId: '',
				title: '',
				description: '',
				type: '',
				time: '',
				queries: '',
				typeQueries: '',
				file: '',
				items: '',
				costDolars: '',
				costPesos: '',
				linktoPayPaypal: '',
				linktoPayMercadoLibre: '',
				nota: '',
				video:'',
				premium:false,
				createdAt: '',
			},
			planData: [],
			imagenes: '',
			msg: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
		if (props.plan !== state.plan) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.plan !== this.props.plan) {
			this.setState({
				plan: this.props.plan,
				planData: this.props.plan,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getPlanById(id);
			return;
		}
    }
    
    
    handlerComprar (Link){
		window.location.assign(Link);
	};
	
	handlerGoBack() {
		this.props.goBack();
	}

  render() {
    const { user } = this.props;
    const token = user && user._id;
    return (
      <>
        <div className="content">
          <Container>
		  <Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#57a97d'}}>Detalles Plan {this.state.plan.type}</h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto " md="9">
              <Card className="card-success">
              <CardBody>
				<CardTitle tag="h1" style={{ textAlign: 'center' }}>
					{this.state.plan.title}{' '}
				</CardTitle>
                {this.state.plan.videoPromo && this.state.plan.videoPromo.length > 3 &&
                    <YouTube videoId={this.state.plan.videoPromo} opts={opts} onReady={this._onReady} />
                }
				<ListGroup>
					<h3>{`El plan tiene una duración de ${this.state.plan.time} Meses`}</h3>
				</ListGroup>
				<div dangerouslySetInnerHTML={{__html:this.state.plan.editorState}} />
				<br />
				<br />
				<br />
				<div className="card-prices text-center">
					<h2 className="text-on-front">
						<span>$ </span>
						{this.state.plan.costPesos} <label>(pesos Uruguayos)</label> 
						{/* <span>U$S </span>{this.state.plan.costDolars} <label>(Dolares USA)</label> */}
					</h2>
				</div>
			</CardBody>
			<CardFooter className="text-center mb-3 mt-3">
				{token ? <ButtonMercadoPago productSelected={this.state.plan}  user={user} typeProduct={"plan"} />
				: (
					<>
						<p className='text-muted'>Para poder comprar necesitamos que te registres</p>
						<Button
							style={{ marginTop: '15px', width: '220px' }}
							className="btn-round"
                            color="warning"
							href="/auth/registrarme"
							size="lg"
						>
							Registrarse
						</Button>
					</>
				)}
			</CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
         
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
	  getPlanById: (id) => dispatch(getPlanById(id)),
	  goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TurnosPresenciales);