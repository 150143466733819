import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { getControlById } from 'actions';
import moment from 'moment';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// reactstrap components
import {
    Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle, ListGroup
} from "reactstrap";



class DetallesControles extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			control: {
				userId: '',
				avatar: '',
				typo: 'Offline',
				masaAdiposa:'',
				sumade6:'',
				masaMuscular:'',
				indice:'',
				talla: '',
				peso: '',
				biceps:'',
				perimetroCintura: '',
				perimetroCadera: '',
				brazoContraido: '',
				objetivo:'',
				notas: '',
				createdAt: '',
			},
			imagenes: '',
			msg: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
		if (props.control !== state.control) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.control !== this.props.control) {
			this.setState({
				control: this.props.control,
				controlData: this.props.control,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getControlById(id);
			return;
		}
    }
    
    
    handlerComprar (Link){
		window.location.assign(Link);
    };

    handlerGoBack() {
		this.props.goBack();
	}

  render() {
    const {control} = this.state;
    const images = control?.avatar.split(',');
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: 1,
        },
    };
    return (
      <>
        <div className="content">
          <Container>
          <Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
              <h3 className="title" style={{color:'#57a97d'}}>Detalles Control {control.typo}</h3>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="9">
              <Card className="card-success">
              <CardBody>
				<CardTitle tag="h1" style={{ textAlign: 'center' }}>
                
				</CardTitle>
				<ListGroup>
                <Carousel
											swipeable={true}
											draggable={true}
											responsive={responsive}
											ssr
											infinite={false}
											containerClass="first-carousel-container container"
											dotListClass="custom-dot-list-style"
										>
                    {images.map((image)=>(
                        <div>
                            <img alt={"imagen Control"} src={`https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/${image}`} width={'100%'} />
                        </div>
                        ))}
                    </Carousel>
                </ListGroup>
                <div style={{padding:'40px', float:'left', textAlign:'left'}}>
                {control.typo === 'Online' && 
                <>
                    <h4> {`Fecha de control: ${moment(control.createdAt).subtract(10, 'days').calendar()}`}</h4>
                    <h4>{`Talla: ${control.talla}`}</h4>
                    <h4>{`Peso: ${control.peso}`}</h4>
                    <h4>{`Perimetro de cintura: ${control.perimetroCintura}`}</h4>
                    <h4>{`Perimetro de cadera: ${control.perimetroCadera}`}</h4>
                    <h4>{`Brazo contraido: ${control.brazoContraido}`}</h4>
                    <h4>Notas:</h4>
                    <p>{`${control.notas}`}</p>
                    </>
                }
                {control.typo === 'Offline' &&
                <>
                    <h4> {`Fecha de control: ${moment(control.createdAt).subtract(10, 'days').calendar()}`}</h4>
                    <h4>{`Masa Adiposa: ${control.masaAdiposa}`}</h4>
                    <h4>{`Peso: ${control.peso}`}</h4>
                    <h4>{`Suma de 6 pliegues: ${control.sumade6}`}</h4>
                    <h4>{`Masa Muscular: ${control.masaMuscular}`}</h4>
                    <h4>{`Indice M/O: ${control.indice}`}</h4>
                    <h4>{`Biceps contraido: ${control.biceps}`}</h4>
                    <h4>Objetivo a lograr:</h4>
                    <p>{`${control.objetivo}`}</p>
                    <h4>Notas:</h4>
                    <p>{`${control.notas}`}</p>
                    </>
                }
                </div>
			</CardBody>
        </Card>
        </Col>
        </Row>
          <Row>
         
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
      getControlById: (id) => dispatch(getControlById(id)),
      goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallesControles);