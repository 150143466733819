const config = {
    shortSiteTitle:"Nutricion Consciente",
    siteTitle:"Nutricion Consciente | nutricionconscienteuy.com",
    siteDescription:"La nutrición consciente representa la alimentación de los niveles físico, emocional, mental y espiritual; al tener un equilibrio de estos factores se logra una salud óptima, un bienestar inigualable y una mejor calidad de vida.",
    siteImage:"https://nutricionconscienteuy.herokuapp.com/static/media/logo.a35e6b26.png",
    pathPrefix:"nutricion ",
    siteUrl:"www.nutricionconscientuy.com",
    authorTwitterAccount:"",
    facebookAppId:"",
}

export default config;