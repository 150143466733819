import React from "react";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Carousel from "react-multi-carousel";
import _ from 'lodash';

import "react-multi-carousel/lib/styles.css";

import {getPlanes} from 'actions';
import Plan from '../../components/Plan';
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 664, min: 0 },
    items: 1
  }
};

class PlanesDistancia extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("planes-a-distancia-page");
    if(!this.props.planes){
      this.props.getPlanes();
    }
  }
  componentWillUnmount() {
    document.body.classList.toggle("planes-a-distancia-page");
  }

  render() {
    const { user } = this.props;
    const {_id: token} = user;
    const { planes} = this.props;

    return (
      <>
        <div className="content">
          <Container>
          <Row style={{marginBottom:"50px"}}>
              <Col md="12">
              </Col>
            </Row>
            <Row style={{marginBottom:"50px"}}>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h1 className="title" style={{color:'#57a97d'}}>Planes personalizados online </h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <p style={{textAlign:'center'}}>
              En esta sección encontrarás planes nutricionales personalizados acorde a tus necesidades y objetivos, (aumento de masa muscular, descenso de grasa, mejorar rendimiento deportivo, alimentación vegana y vegetariana, entre otros). Para ello deberás completar el formulario para conocer tu rutina, hábitos de alimentación, entrenamiento, etc.
              Una vez recibido el pago y completado el formulario, recibirás en un plazo de 10 días hábiles tú  plan nutricional.
              </p>
              <p> </p>
              </Col>
            </Row>
            <Row style={{marginTop:'40px'}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title">Planes</h1>
                <h6 style={{color:'#57a97d'}}>NOTA: UNA VEZ EFECTUADO EL PAGO, PODRÁS ACCEDER AUTOMÁTICAMENTE A COMPLETAR EL FORMULARIO CON LOS DATOS NECESARIOS PARA COMENZAR A TRABAJAR EN TU PLAN NUTRICIONAL.
              </h6>
              </Col>
            </Row>
            <Row style={{marginBottom:"50px"}}>

            <Col md="12">
                            <Carousel
                                swipeable={true}
                                draggable={false}
                                responsive={responsive}
                                ssr
                                autoPlay
                                autoPlaySpeed={4000}
                                infinite={true}
                                containerClass="first-carousel-container container"
                                dotListClass="custom-dot-list-style"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                            >
                              {planes ? _.compact(planes?.map((plan) => plan.type === 'Online' ? <div><Plan user={user} planDetail={plan}/> </div> : null)): <div>   </div>}
                            </Carousel>
                                </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
    mercadopago: (link) => dispatch(push(link)),
    getPlanes: () => dispatch(getPlanes()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanesDistancia);