import React from 'react';
import { connect } from 'react-redux';
import { newPassword } from '../../actions/';
import NotificationAlert from 'react-notification-alert';

import classnames from 'classnames';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            token:'',
            confirmToken:'',
            password: '',
			passwordConfirm: '',
			msg: '',
		};
	}

	componentDidMount() {
        const { token , id } = this.props.match.params;
		this.setState({...this.state, token:token , confirmToken:id});
	}
	componentWillUnmount() {
		document.body.classList.toggle('ChangePassword-page');
	}

    static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return {
            };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
				this.setState({
					msg: this.props.msg,
                });
                this.notify('br', this.props.msg);
			}
	}



	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	verifyPasswords = value => {
		const { password } = this.state;
		if (password === value) {
			return true;
		}
		return false;
	};

	verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};

	verifyPasswordsData = (value) => {
		const content = value.length;

		if (content >= 8) {
			return true;
		}
		return false;
	};

	isValidated = () => {
		if (
			this.state.passwordState === 'has-success' &&
			this.state.passwordConfirmState === 'has-success') {
			return true;
		} else {
			if (this.state.passwordState !== 'has-success') {
				this.notify('br', 'Passwords incorrecto tiene que tener 8 caracteres minimo');
				this.setState({
					passwordState: 'has-danger',
					passwordConfirmState: 'has-danger'
				});
			}
			return false;
		}
	};

	change = (event, stateName, type, stateNameEqualTo, maxValue) => {
		switch (type) {
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'number':
				if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'equal':
				if (this.verifyPasswords(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'passwordV':
				if (this.verifyPasswordsData(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger', msg: 'Los passwords no son iguales' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	};

	handlerSend = () => {

        console.log(this.isValidated());
		if (this.isValidated()) {
			const data = { newPassword: this.state.password,token:this.state.token};
			this.props.newPassword(data);
		}
	};

	goToUrl = (url, target = '_self') => {
		window.open(url, target);
	};

	render() {
		return (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<div className="content">
					<Container>
						<Row style={{ marginBottom: '50px' }}>
							<Col className="ml-auto mr-auto text-center" md="6">
								<h1 className="title" style={{ color: '#57a97d' }}>
									Cambia tu contraseña
								</h1>
                                <p>Escribe tu nueva contraseña , una vez que la cambies el sistema te redireccionará a el login para que te logues y la confirmes</p>
							</Col>
						</Row>
						<Row>
							<Col className="ml-auto mr-auto text-center" md="4">
								<Card className="card-register">
									<CardBody>
										<Form className="form">
                                        <InputGroup
												className={classnames(this.state.passwordState, {
													'input-group-focus': this.state.passwordFocus,
												})}
											>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
													name="password"
													placeholder="Nueva Contraseña..."
													type="password"
													onChange={e => this.change(e, 'password', 'passwordV')}
													onFocus={e => this.setState({ passwordFocus: true })}
													onBlur={e => this.setState({ passwordFocus: false })}
												/>
                      </InputGroup>
                      <InputGroup
												className={classnames(this.state.passwordConfirmState, {
													'input-group-focus': this.state.passwordConfirmFocus,
												})}
											>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
													name="passwordConfirm"
													placeholder="Repita la nueva Contraseña..."
													type="password"
													onChange={e => this.change(e, 'passwordConfirm', 'equal')}
													onFocus={e => this.setState({ passwordConfirmFocus: true })}
													onBlur={e => this.setState({ passwordConfirmFocus: false })}
												/>
                      </InputGroup>
                      <p>Recomendamos contraseña mayor a 8 dígitos agregale algun simbolo y mezcla mayúsculas y minúsculas</p>
										</Form>
									</CardBody>
									<CardFooter>
										<Button
											style={{ marginTop: '15px', width: '300px' }}
											className="btn-round"
											color="success"
											href="#Matias"
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Cambiar contraseña
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		newPassword: (data) => dispatch(newPassword(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);