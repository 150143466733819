import React from 'react';
import { connect } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import {getComprasByUserId} from 'actions';
import moment from 'moment';

import classnames from 'classnames';
import Loader from 'assets/img/loadern.gif';

// reactstrap components
import {
    Container,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Table,
} from 'reactstrap';

class MisCompras extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            user:{},
            compras:{},
			msg: '',
			buscar: '',
			orderBy: 'desc',
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.compras !== this.props.compras) {
			this.setState({
				compras: this.props.compras,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getComprasByUserId(id);
			return;
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	verDetalle = (id) => {
		console.log(`Ver ${id}`);
	};

	descargarArchivos=(id)=>{
		console.log(`Descargar archivos ${id}`);
	}

	handlerDetailsProduct = (e, productSelected) => {
		console.log(productSelected);
	}

	listCompras = () => {
	const { compras } = this.state;
		return (
			<tbody>
			{_.map(compras.compras , (x)=>(
				<tr key={x._id}>
                <td className="text-center">{x._id}</td>
				<td>{x.producto}</td>
				<td>${x.monto} </td>
				<td className="text-right">{x.plataforma}</td>
				<td className="text-right">{moment(x.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
				<td className="text-center">
					{x.producto !== 'Turno Presencial'
					? <Button
						className="btn-link"
						color="success"
						href={`/auth/archivos/${x.productoId}`}
						size="sm"
					>
						<i className="icon-cloud-download" style={{fontSize:'20px'}}/>
					</Button>
					:
					<Button
						className="btn-link"
						color="success"
						onClick={(e)=> this.handlerDetailsProduct(e, x)}
						size="sm"
					>
						<i className="icon-line-eye" style={{fontSize:'20px'}}/>
					</Button>

					}

				</td>
			</tr>
			))}
			</tbody>
		);
	};

	render() {
		const { loading } = this.props;
		return (
			loading ? <div><img src={Loader} width="50px" alt=""/>loading...</div>
				:
				(
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>

				<div className="content">
                <Container>
					<Row>
						<Col md="12" className="ml-auto mr-auto text-left">
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<div className="tools float-right">
										<InputGroup
											className={classnames(this.state.buscarState, {
												'input-group-focus': this.state.buscarFocus,
											})}
										>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="tim-icons icon-zoom-split" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												name="buscar"
												placeholder="Buscar..."
												type="text"
												onChange={(e) => this.change(e)}
												onFocus={(e) => this.setState({ buscarFocus: true })}
												onBlur={(e) => this.setState({ buscarFocus: false })}
											/>
										</InputGroup>
									</div>
									<CardTitle tag="h4" className="text-left">Listado Compras</CardTitle>
								</CardHeader>
								<CardBody>
									<Table responsive style={{ overflow: 'none' }}>
										<thead className="text-primary">
											<tr>
                                                <th className="text-center">#id transacción</th>
												<th className="text-center">Producto</th>
												<th className="text-center">Monto</th>
												<th className="text-center">Plataforma</th>
												<th className="text-right">Fecha</th>
												<th className="text-right">Acciones</th>
											</tr>
										</thead>
										{this.listCompras()}
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
                    </Container>
				</div>
			</>
			)
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getComprasByUserId: id => dispatch(getComprasByUserId(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MisCompras);