import { actions } from '../constants';
import { takeLatest } from 'redux-saga/effects';

import {
	login,
	regitrarUsuario,
	imageNames,
	userData,
	updateUser,
	getMensajes,
	sendMensaje,
	changeStatusMensaje,
	getMensajesEnviados,
	getUsers,
	getRecetas,
	getPlanes,
	getAgenda,
	getUserById,
	registrarUsuarioNuevo,
	deleteUser,
	getControllsByUserId,
	registrarAnamnesisNuevo,
    getAnamnesisById,
	updateEditedAnamnesis,
	newPlan,
	updateEditedPlan,
	getPlanById,
	deletePlan,
	updateEditedControl,
	newControl,
	getControlById,
	deleteControl,
	newReceta,
	updateEditedReceta,
	getRecetaById,
	deleteReceta,
	getTipById,
	updateEditedTip,
	updateEditedHomeSlider,
	newTip,
	newHomeSlider,
	getTips,
	getHomeSlider,
	deleteTip,
	newFile,
	updateEditedFile,
	getFileById,
	mercadoPagoBuy,
	getComprasByUserId,
	getVentas,
	updateEditedHomeSliderMobile,
	newHomeSliderMobile,
	getHomeSliderMobile,
	forgotPassword,
	newPassword,
	newCalendar,
	updateEditedCalendar,
	removeControl,
	AssignPlan
} from './index';

export function* generator() {
	yield takeLatest(actions.LOGIN, login);
	yield takeLatest(actions.UPDATE_USER, updateUser);
	yield takeLatest(actions.REGISTRAR_USUARIO, regitrarUsuario);
	yield takeLatest(actions.IMAGE_NAME, imageNames);
	yield takeLatest(actions.USER_DATA, userData);
	yield takeLatest(actions.GET_MENSAJES,getMensajes);
	yield takeLatest(actions.GET_MENSAJES_ENVIADOS,getMensajesEnviados);
	yield takeLatest(actions.SEND_MENSAJE,sendMensaje);
	yield takeLatest(actions.CHANGE_STATUS_MENSAJE,changeStatusMensaje);
	yield takeLatest(actions.GET_USERS, getUsers);
	yield takeLatest(actions.GET_RECETAS, getRecetas);
	yield takeLatest(actions.GET_PLANES, getPlanes);
	yield takeLatest(actions.GET_AGENDA, getAgenda);
	yield takeLatest(actions.GET_USER_BY_ID, getUserById);
	yield takeLatest(actions.REGISTRAR_USUARIO_NUEVO, registrarUsuarioNuevo);
	yield takeLatest(actions.DELETE_USER, deleteUser);
	yield takeLatest(actions.GET_CONTROLLS_BY_USER_ID , getControllsByUserId);
	yield takeLatest(actions.REGISTRAR_ANAMNESIS_NUEVO, registrarAnamnesisNuevo);
	yield takeLatest(actions.GET_ANAMNESIS_BY_ID, getAnamnesisById);
	yield takeLatest(actions.UPDATE_ANAMNESIS, updateEditedAnamnesis);
	yield takeLatest(actions.NEW_PLAN, newPlan);
	yield takeLatest(actions.UPDATE_PLAN, updateEditedPlan);
	yield takeLatest(actions.GET_PLAN_BY_ID, getPlanById);
	yield takeLatest(actions.DELETE_PLAN, deletePlan);
	yield takeLatest(actions.DELETE_RECETA, deleteReceta);
	yield takeLatest(actions.UPDATE_CONTROLL, updateEditedControl);
	yield takeLatest(actions.NEW_CONTROLL, newControl);
	yield takeLatest(actions.GET_CONTROLL_BY_ID, getControlById);
	yield takeLatest(actions.DELETE_CONTROL, deleteControl);
	yield takeLatest(actions.NEW_RECETA, newReceta);
	yield takeLatest(actions.UPDATE_RECETA, updateEditedReceta);
	yield takeLatest(actions.GET_RECETA_BY_ID, getRecetaById);
	yield takeLatest(actions.GET_TIP_BY_ID, getTipById);
	yield takeLatest(actions.UPDATE_TIP, updateEditedTip);
	yield takeLatest(actions.UPDATE_HOME_SLIDER, updateEditedHomeSlider);
	yield takeLatest(actions.UPDATE_HOME_MOBILE_SLIDER, updateEditedHomeSliderMobile);
	yield takeLatest(actions.NEW_TIP, newTip);
	yield takeLatest(actions.NEW_HOME_SLIDER, newHomeSlider);
	yield takeLatest(actions.NEW_HOME_MOBILE_SLIDER, newHomeSliderMobile);
	yield takeLatest(actions.GET_TIPS, getTips);
	yield takeLatest(actions.GET_HOME_SLIDER, getHomeSlider);
	yield takeLatest(actions.DELETE_TIP, deleteTip);
	yield takeLatest(actions.NEW_FILE, newFile);
	yield takeLatest(actions.NEW_MERCADO_PAGO_SELL, mercadoPagoBuy);
	yield takeLatest(actions.UPDATE_FILE, updateEditedFile);
	yield takeLatest(actions.GET_FILE_BY_ID, getFileById);
	yield takeLatest(actions.GET_COMPRAS_BY_USER_ID, getComprasByUserId);
	yield takeLatest(actions.GET_VENTAS, getVentas);
	yield takeLatest(actions.GET_HOME_SLIDER_MOBILE, getHomeSliderMobile);
	yield takeLatest(actions.FORGOT_PASSWORD, forgotPassword);
	yield takeLatest(actions.NEW_PASSWORD, newPassword);
	yield takeLatest(actions.NEW_CALENDAR, newCalendar);
	yield takeLatest(actions.UPDATE_CALENDAR, updateEditedCalendar);
	yield takeLatest(actions.REMOVE_CONTROL, removeControl);
	yield takeLatest(actions.ASSIGN_PLAN, AssignPlan);
}
