import React from "react";
import { connect } from "react-redux";

import { Card, CardBody, CardHeader, Row, Col, CardTitle } from "reactstrap";
import { push } from "react-router-redux";
import AuthNavbar from "../../components/Navbars/AuthNavbar";

class PoliticasPrivacidad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12" style={{padding: "50px"}}>
              <Card>
                <CardHeader className="mb-5">
                  <h5 className="card-category">NutricionConscienteUY.com</h5>
                  <CardTitle tag="h3">Política de Privacidad</CardTitle>
                </CardHeader>
                <CardBody>
                  <p>
                    <span className={"text-success"}>1. NUTRICIONCONSCIENTEUY.COM</span>, es propietaria de este sitio web y sólo
                    recogerá información que contenga datos personales de
                    usuarios y clientes que brinden su consentimiento para ello.
                  </p>

                  <p>
                  <span className={"text-success"}>2. NUTRICIONCONSCIENTEUY.COM </span>, tratará los datos personales proporcionados
                    por los usuarios y clientes de este sitio, únicamente con la
                    finalidad y en la modalidad detallada en esta Política de
                    Privacidad y en un todo de acuerdo a la normativa de la
                    República Oriental del Uruguay en materia de Protección de
                    Datos Personales, en particular a la Ley No. 18.331, La Ley
                    No. 18.719 y los decretos No. 664/008 de 22-12-008 y 414/009
                    de 31-8-009.
                  </p>

                  <p>
                  <span className={"text-success"}>3.NUTRICIONCONSCIENTEUY.COM </span>, se compromete a usar los datos personales
                    que reciba, exclusivamente para los fines específicos para
                    los cuales el usuario o cliente los proporciona, no
                    cediéndolos a terceros. Sin perjuicio de ello, se reserva la
                    posibilidad de realizar el tratamiento de datos personales
                    de los usuarios para utilizarlos en los otros servicios de
                    la empresa o de sus socios de negocios.
                  </p>

                  <p>
                  <span className={"text-success"}>4.</span> Los datos personales podrán alojarse en los servidores
                    que posee la empresa actualmente o de futuro en Uruguay o en
                    otros países con un nivel de protección adecuado.
                  </p>

                  <p>
                  <span className={"text-success"}>5.</span> Los suscriptores recibirán uno o más de un mail por día
                    con ofertas de nuestro sitio y/o avisos de productos o
                    servicios relacionados a <span className={"text-success"}>NUTRICIONCONSCIENTEUY.COM.</span> Los suscriptores
                    pueden en cualquier momento cancelar su suscripción a los
                    distintos boletines de correo electrónico, de forma total o
                    parcial, haciendo clic en el link ‘Cancelar subscripción’
                    ubicado en la parte inferior de los correo electrónicos
                    enviados por <span className={"text-success"}>NUTRICIONCONSCIENTEUY.COM.</span>.
                  </p>

                  <p>
                  <span className={"text-success"}>6.</span> Mediante el vínculo “CONTACTO”, el usuario o cliente
                    podrá requerir cualquier tipo de información o realizar
                    comentarios a <span className={"text-success"}>NUTRICIONCONSCIENTEUY.COM</span>, y en ese caso, los datos
                    personales que proporcione se archivarán en una base de
                    datos de “CONTACTOS” o “USUARIOS REGISTRADOS/CLIENTES” y
                    sólo se utilizarán para evacuar las consultas, extraer
                    información estadística o comunicarse con el solicitante.
                  </p>

                  <p>
                  <span className={"text-success"}>7.</span>  Cuando el cliente contrate con <span className={"text-success"}>NUTRICIONCONSCIENTEUY.COM</span> , los datos
                    personales que proporcione sólo serán utilizados para
                    cumplir lo establecido en la relación contractual, y se
                    incluirán en la base de datos “USUARIOS
                    REGISTRADOS/CLIENTES”, con el único fin de control de la
                    empresa y del cliente.
                  </p>

                  <p>
                  <span className={"text-success"}>8.</span>  La Política de Privacidad se limita a la utilización que
                    el usuario haga directamente ingresando a la página web, y
                    no se garantiza el mismo resultado en caso de que se acceda
                    mediante el redireccionamiento de otros sitios.
                  </p>

                  <p>
                  <span className={"text-success"}>9.</span> En esta Política de Privacidad se detallan las prácticas
                    vigentes de la empresa en relación a los productos que
                    comercializa y servicios que presta. Por ello, la misma
                    puede ser alterada si <span className={"text-success"}>NUTRICIONCONSCIENTEUY.COM</span>, a su sola voluntad,
                    decide hacerlo.
                  </p>

                  <p>
                  <span className={"text-success"}>10. NUTRICIONCONSCIENTEUY.COM</span>, ha implementado todas las medidas de
                    seguridad necesarias para evitar que terceros no autorizados
                    accedan a los datos. Ello incluye las medidas de seguridad
                    físicas y el acceso sólo de empleados o subcontratistas que
                    necesiten acceder a los datos por razones laborales, los
                    cuales estarán sujetos a cláusulas de confidencialidad y
                    obligaciones de secreto profesional, contemplado en el art.
                    302 del Código Penal.
                  </p>

                  <p>
                  <span className={"text-success"}>11. NUTRICIONCONSCIENTEUY.COM</span>, hará los máximos esfuerzos para proteger
                    los datos personales que le proporcionen los usuarios o
                    clientes por esta web. Sin embargo, si en cumplimiento de
                    obligaciones contractuales que asuma con terceros, o en el
                    caso de que exista una orden judicial al respecto, podrá
                    revelar algunos de los datos que tenga en su poder.
                  </p>

                  <p>
                  <span className={"text-success"}>12.</span> El Responsable de las bases de datos de “CONTACTOS” y
                    “USUARIOS REGISTRADOS/CLIENTES” es NUTRICIONCONSCIENTEUY.COM, pudiéndose
                    ejercer los derechos de acceso, modificación o supresión
                    dirigiéndose en forma escrita por mail.
                  </p>

                  <p>
                  <span className={"text-success"}>13.</span> Si tiene consultas o sugerencias sobre esta Política de
                    privacidad de este sitio web, diríjase al e-mail
                    contacto@nutricionconscienteuy.com
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  //console.log(JSON.stringify(state));
  return {
    ...state.reducers.homeReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goTo: path => {
      dispatch(push(path));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PoliticasPrivacidad);
