import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/';
import NotificationAlert from 'react-notification-alert';
import _ from 'lodash';

import classnames from 'classnames';

import  Usuarios from 'components/Admin/ListadoUsuarios';
import  Recetas from 'components/Admin/ListadoRecetas';
import  Planes from 'components/Admin/ListadoPlanes';
import  Ventas from 'components/Admin/Ventas';
import  Agenda from 'components/Admin/Agenda';
import Editar from 'components/Admin/Editar';

// reactstrap components
import {
	Button,
	Container,
	Row,
	Col,
} from 'reactstrap';

const activeStatusAdmin = {
	USUARIOS: 'usuarios',
	AGENDA: 'agenda',
	PLANES: 'planes',
    RECETAS: 'recetas',
    VENTAS:'ventas',
    EDITAR:'editar'
};

class Administracion extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: activeStatusAdmin.USUARIOS,
			msg: '',
		};
	}

	componentDidMount() {
		document.body.classList.toggle('admin-page');
	}
	componentWillUnmount() {
		document.body.classList.toggle('admin-page');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	goToUrl = (url, target = '_self') => {
		window.open(url, target);
	};

	showActiveSection = () => {
		const { active } = this.state;
		switch (active) {
			case activeStatusAdmin.USUARIOS:
				return <Usuarios />;
			case activeStatusAdmin.AGENDA:
				return <Agenda /> ;
			case activeStatusAdmin.PLANES:
				return <Planes />
			case activeStatusAdmin.RECETAS:
                return <Recetas />;
            case activeStatusAdmin.VENTAS:
                return <Ventas />;
            case activeStatusAdmin.EDITAR:
                return <Editar />;
			default:
				return <Usuarios />;
		}
	};

	render() {
		return (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container>
					<Row style={{ marginTop: '50px', marginBottom: '30px' }}>
						<Col className="ml-auto mr-auto text-center" md="12">
							<Button
								style={{ margin: '15px', width: '150px' }}
								className="btn-round"
								color="success"
								onClick={() => this.setState({ active: activeStatusAdmin.USUARIOS })}
								size="lg"
							>
								Usuarios
							</Button>
							<Button
								style={{ margin: '15px', width: '150px' }}
								className="btn-round"
								color="success"
								onClick={() => this.setState({ active: activeStatusAdmin.AGENDA })}
								size="lg"
							>
								Agenda
							</Button>
							<Button
								style={{ margin: '0px', width: '200px' }}
								className="btn-round"
								color="success"
								onClick={() => this.setState({ active: activeStatusAdmin.PLANES })}
								size="lg"
							>
								Planes / Ebooks
							</Button>
							<Button
								style={{ margin: '15px', width: '150px' }}
								className="btn-round"
								color="success"
								onClick={() => this.setState({ active: activeStatusAdmin.RECETAS })}
								size="lg"
							>
								Recetas
							</Button>
                            <Button
								style={{ margin: '15px', width: '150px' }}
								className="btn-round"
								color="success"
								onClick={() => this.setState({ active: activeStatusAdmin.VENTAS })}
								size="lg"
							>
								Ventas
							</Button>
                            <Button
								style={{ margin: '10px', width: '170px' }}
								className="btn-round"
								color="success"
								onClick={() => this.setState({ active: activeStatusAdmin.EDITAR })}
								size="lg"
							>
							  Editar Web
							</Button>
						</Col>
					</Row>
					<Row style={{ marginBottom: '50px' }} md="12">
						<Col className="ml-auto mr-auto text-center" md="12">
							{this.showActiveSection()}
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (data) => dispatch(login(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Administracion);
