import React from 'react';

import { Button, Card, CardBody, CardFooter, CardImg } from 'reactstrap';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { split, truncate } from 'lodash';

const Tip = (props) => {
    const { tipDetail } = props;
	const images = tipDetail.avatar !== '' ? split(tipDetail.avatar, ',') : [];
    
    const handlerVerDetallesPlanes = id => {
        props.irDetallesTip(id);
      }

	return (
		<div style={{width:'90%', margin:'2px', maxHeight:'100vh'}}>
		<Card className="card-pricing card-success" style={{borderRadius:'20px' }}>
			<CardImg
			style={{ borderRadius: '20px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px'}}
			onClick={() => handlerVerDetallesPlanes(tipDetail._id)}
			alt="..."
			src={`https://d39etc8ensw3fs.cloudfront.net/fit-in/250x280/${images[0]}`} />
			<CardBody>
			
				<div className="card-prices" style={{textAlign:'center', margin:'10px', height:'100px'}}>
					<h5 className="text-success">
                    {tipDetail.title}
					</h5>
					<p className='text-muted' style={{textAlign:'center'}}>{truncate(tipDetail.description, { length: 80, separator: ' ...' }) }</p>
				</div>
				
				<div style={{textAlign:'center'}}>
				
				</div>
			</CardBody>
			<CardFooter className="text-center mb-1 mt-1">
            <Button
						onClick={() => handlerVerDetallesPlanes(tipDetail._id)}
						className="btn-round btn-just-icon"
                        color="warning"
						style={{marginLeft:'auto', marginRight:'auto'}}
					>
						Ver Tip
					</Button>
			</CardFooter>
		</Card>
        </div>
	);
};


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      irDetallesTip:(id) => dispatch(push(`tip-detalle/${id}`))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tip);