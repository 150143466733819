import React from 'react';

import { Button, Card, CardBody, CardFooter, CardImg} from 'reactstrap';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { split } from 'lodash';
import ButtonMercadoPago from 'components/ButtonMercadoPago';

const Receta = (props) => {
    const { user, recetaDetail } = props;
	const token = user._id;
	const images = recetaDetail.avatar !== '' ? split(recetaDetail.avatar, ',') : [];
    
    const handlerVerDetallesPlanes = id => {
        props.irDetallesReceta(id);
      }

	return (
		<div style={{width:'90%', margin:'0px', maxHeight:'100vh'}}>
		<Card className="card-pricing card-success" style={{borderRadius:'20px' }}>
		<CardImg style={{ borderRadius: '20px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px'}} onClick={() => handlerVerDetallesPlanes(recetaDetail._id)} alt="..." src={`https://d39etc8ensw3fs.cloudfront.net/fit-in/280x250/${images[0]}`} />
			<CardBody>
			
				<div className="card-prices">
					<h3 className="text-on-front">
						<span>$</span>
						{recetaDetail.costPesos}
					</h3>
					<h5 className="text-on-back" style={{fontSize:'90px',height:'100px'}}>{recetaDetail.costPesos}</h5>
					<p className='text-muted' style={{textAlign:'center'}}>{recetaDetail.title}</p>
				</div>
				
				<div style={{textAlign:'center'}}>
				<Button
						onClick={() => handlerVerDetallesPlanes(recetaDetail._id)}
						className="btn-round btn-just-icon"
                        color="warning"
						style={{marginLeft:'auto', marginRight:'auto'}}
					>
						Mas información
					</Button>
				</div>
			</CardBody>
			<CardFooter className="text-center mb-1 mt-1">
				{token ? <ButtonMercadoPago productSelected={recetaDetail}  user={user} typeProduct={"receta"} />
				: (
					<>
						<p className='text-muted'>Para poder comprar necesitamos que te registres</p>
						<Button
							style={{ marginTop: '15px', width: '220px' }}
							className="btn-round"
                            color="warning"
							href="/auth/registrarme"
							size="lg"
						>
							Registrarse
						</Button>
					</>
				)}
			</CardFooter>
		</Card>
        </div>
	);
};


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
      irDetallesReceta:(id) => dispatch(push(`recetas/${id}`))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Receta);