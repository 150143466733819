import axios from 'axios';
import client from "../apiCliente.js";


/* const URL_BASE = "http://localhost:3300/"; */
const URL_BASE = 'https://calendar-apirest.herokuapp.com/';

let token = localStorage.getItem("token");

export const updateUser = (data, id) =>{
		return axios.post(`${URL_BASE}user/${id}`, data, {
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
							"authorization": "Bearer " + token,
							'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
						},
					});
	}

export const getAnamnesisById = (id) =>{
	return client().get(`${URL_BASE}anamnesis-by-user-id/${id}`);
}

export const registrarAnamnesisNuevo = data => {
	return client().post(`${URL_BASE}new-anamnesis/`, data);
};

export const getPlanById = (id) =>{
	return client().get(`${URL_BASE}plans/${id}`);
}


export const getComprasByUserId = (id) =>{
	return client().get(`${URL_BASE}compras/${id}`);
}

export const getVentas = () =>{
	return client().get(`${URL_BASE}ventas`);
}

export const getFileById = (id) =>{
	return client().get(`${URL_BASE}file/${id}`);
}

export const newPlan = data => {
	return client().post(`${URL_BASE}new-plan/`, data);
};

export const updateEditedPlan = (data, id) =>{
	return client().put(`${URL_BASE}plans/${id}`, data);
}

export const getRecetaById = (id) =>{
	return client().get(`${URL_BASE}receta/${id}`);
}

export const newReceta = data => {
	return client().post(`${URL_BASE}new-receta/`, data);
};

export const newTip = data => {
	return client().post(`${URL_BASE}new-tip/`, data);
};

export const newHomeSlider = data => {
	return client().post(`${URL_BASE}new-home-slider/`, data);
};

export const newHomeSliderMobile = data => {
	return client().post(`${URL_BASE}new-home-mobile-slider/`, data);
};



export const updateEditedReceta = (data, id) =>{
	return client().put(`${URL_BASE}receta/${id}`, data);
}

export const updateEditedAnamnesis = (data, id) =>{
	return client().put(`${URL_BASE}anamnesis/${id}`, data);
}


export const getMensajes = id => {
	return client().get(`${URL_BASE}getMessagesByUserId/${id}`);
};

export const getMensajesEnviados = id => {
	return client().get(`${URL_BASE}mensajes-enviados/${id}`);
};

//emails
export const sendMailRegistroUsuario  = data => {
	return client().post(`${URL_BASE}send-email-new-user`, data);
};

export const sendMailNewUserToAdmin  = data => {
	return client().post(`${URL_BASE}send-email-new-user-admin`, data);
};

export const sendMailContactoToAdmin = data => {
	return client().post(`${URL_BASE}send-email-contacto-admin`, data);
};


export const sendMailNewPlan = data => {
	return client().post(`${URL_BASE}send-email-new-plan`, data);
};

export const sendMailNotificatioNewMessage = data => {
	return client().post(`${URL_BASE}send-email-new-message`, data);
};

export const resetPasswordUser = payload => {
	return client().post(`${URL_BASE}send-email-reset-password-user`, payload);
};

export const getControllsByUserId = id => {
	return client().get(`${URL_BASE}controls-by-user-id/${id}`);
};


export const getControlById = (id) =>{
	return client().get(`${URL_BASE}controls/${id}`);
}

export const getTipById = (id) =>{
	return client().get(`${URL_BASE}tip/${id}`);
}

export const newControl = data => {
	return client().post(`${URL_BASE}new-control/`, data);
};

export const newCalendar = data => {
	return client().post(`${URL_BASE}new-calendar`, data);
};

export const AssignPlan = data => {
	return client().post(`${URL_BASE}manualsuccess`, data);
};



export const newFile = data => {
	return client().post(`${URL_BASE}new-file/`, data);
};

export const mercadoPagoBuy = data => {
	return client().post(`${URL_BASE}mercadoPago/`, data);
};

export const updateEditedControl = (data, id) => {
	return client().put(`${URL_BASE}controls/${id}`, data);
}

export const updateEditedCalendar = (data, id) => {
	return client().put(`${URL_BASE}calendar/${id}`, data);
}

export const updateEditedTip = (data, id) => {
	return client().put(`${URL_BASE}tip/${id}`, data);
}

export const updateEditedHomeSlider = (data, id) => {
	return client().put(`${URL_BASE}homeSlider/${id}`, data);
}

export const updateEditedHomeSliderMobile = (data, id) => {
	return client().put(`${URL_BASE}homeSliderMobile/${id}`, data);
}

export const updateEditedFile = (data, id) => {
	return client().put(`${URL_BASE}file/${id}`, data);
}

export const sendMensaje  = data => {
	return client().post(`${URL_BASE}sendmensaje/`, data.data);
};

export const changeStatusMensaje  = id => {
	return client().get(`${URL_BASE}mensaje/${id}`);
};

export const changeStatusEmpresa  = id => {
	return client().get(`${URL_BASE}empresastatus/${id}`);
};

export const userData = () => {
	return client().get(`${URL_BASE}userData`);
};

export const getUsers = () => {
	return client().get(`${URL_BASE}users`);
};

export const getUserById = (id) =>{
	return client().get(`${URL_BASE}user/${id}`);
}

export const getRecetas = () => {
	return client().get(`${URL_BASE}receta`);
};

export const getPlanes = () => {
	return client().get(`${URL_BASE}plans`);
};

export const getTips = () => {
	return client().get(`${URL_BASE}tips`);
};

export const getHomeSlider = () => {
	return client().get(`${URL_BASE}homeSlider`);
};

export const getHomeSliderMobile = () => {
	return client().get(`${URL_BASE}homeSliderMobile`);
};

export const getAgenda = () => {
	return client().get(`${URL_BASE}calendar`);
};



export const registrarUsuario = data => {
	return client().post(`${URL_BASE}new-user`, data.payload);
};

export const registrarUsuarioNuevo = data => {
	return axios.post(`${URL_BASE}new-user-by-admin`, data, {
		headers: {
			accept: 'application/json',
			'Accept-Language': 'en-US,en;q=0.8',
			'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
		},
	});
};

export const deleteUser = id => {
	return client().delete(`${URL_BASE}user/${id}`);
};

export const removeControl = id => {
	return client().delete(`${URL_BASE}calendar/${id}`);
};

export const deletePlan = id => {
	return client().delete(`${URL_BASE}plan/${id}`);
};

export const deleteTip = id => {
	return client().delete(`${URL_BASE}tip/${id}`);
};

export const deleteControl = id => {
	return client().delete(`${URL_BASE}control/${id}`);
};

export const deleteReceta = id => {
	return client().delete(`${URL_BASE}receta/${id}`);
};

export const login = data => {
	return client().post(`${URL_BASE}login`, data.payload);
};

export const forgotPassword = data => {
	return client().post(`${URL_BASE}forgotPassword`, data.payload);
};

export const newPassword = data => {
	return client().post(`${URL_BASE}newPassword`, data.payload);
};
