import React from 'react';

import { Button, Card, CardBody, CardFooter, CardImg, CardTitle, ListGroup } from 'reactstrap';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import ButtonMercadoPago from 'components/ButtonMercadoPago';


const Plan = (props) => {
    const { user, planDetail } = props;
	const token = user._id;
    const handlerVerDetallesPlanes = id => {
        props.irDetallesPlan(id);
      }

	return (
        <div style={{width:'90%', margin:'0px', maxHeight:'100vh'}}>
		<Card className={`card-pricing card-success ${planDetail.type ==='Online' ? '' :'card-white'}`} style={{borderRadius:'20px'}}>
			<CardBody>
				<CardTitle tag="h1" style={{ textAlign: 'left' }}>
					{planDetail.title}{' '}
				</CardTitle>
				<CardImg onClick={() => handlerVerDetallesPlanes(planDetail._id)} alt="..." src={planDetail.premium ? require('assets/img/card-warning.png'):require('assets/img/card-success.png')} />
				<ListGroup style={{zIndex:100000, marginTop:'140px'}}>
                    <h4 className='text-muted'>Plan {`${planDetail.type}`}</h4>
                    <h4 className='text-muted'>Duración {`${planDetail.time} meses`}</h4>
                    <p className='text-muted'>{ _.truncate(planDetail.description, { length: 30, separator: ' ...' })  }</p>
                    <Button
						onClick={() => handlerVerDetallesPlanes(planDetail._id)}
						className="btn-round btn-just-icon"
                        color="warning"
					>
						Mas información
					</Button>
				</ListGroup>

				<div className="card-prices">
					<h3 className="text-on-front">
						<span>$</span>
						{planDetail.costPesos}
					</h3>
					<h5 className="text-on-back" style={{fontSize:'90px',height:'100px'}}>{planDetail.costPesos}</h5>
					<p className='text-muted' style={{textAlign:'center'}}>Costo por Plan</p>
				</div>
			</CardBody>
			<CardFooter className="text-center mb-3 mt-3">
				{token ? <ButtonMercadoPago productSelected={planDetail}  user={user} typeProduct={"plan"} />
				: (
					<>
						<p className='text-muted'>Para poder comprar necesitamos que te registres</p>
						<Button
							style={{ marginTop: '15px', width: '220px' }}
							className="btn-round"
                            color="warning"
							href="/auth/registrarme"
							size="lg"
						>
							Registrarse
						</Button>
					</>
				)}
			</CardFooter>
		</Card>
        </div>
	);
};


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
      irDetallesPlan:(id) => dispatch(push(`planes/${id}`))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);