import React from "react";
import { Container, Row, Col,Button } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import logo from "assets/img/logo2.png";

class Footer extends React.Component {
  render() {
    return (
      <footer
      className={"footer" + (this.props.default ? " footer-default" : "")}
    >
      <Container fluid={true}>
      <Row md="12">
      <Col style={{textAlign:'left', marginBottom:"30px"}} md="9">
         <img alt="#" src={logo} width="220px"></img>
      </Col>
      </Row>
      <Row md="12">
      
      <Col md="4">
            <p>contacto@nutricionconscienteuy.com</p>
              <Button
                  className="btn-icon btn-simple"
                  color="facebook"
                  href='https://www.facebook.com/nccsuy/'
                >
                  <i className="fab fa-facebook-square ml-auto" />
                </Button>
                <Button
                  className="btn-icon btn-simple"
                  color="twitter"
                  href='https://twitter.com/nutricionconsc3'
                >
                  <i className="fab fa-twitter ml-auto" />
                </Button>
                <Button
                  className="btn-icon btn-simple"
                  color="primary"
                  href='https://www.instagram.com/nutricionconscienteuy/'
                >
                  <i className="fab fa-instagram ml-auto mr-auto" />
                </Button>
                <Button
                  className="btn-icon btn-simple"
                  style={{color:"red",borderColor:"red"}}
                  onClick={e => {
                    this.goToUrl('#');
                  }}
                >
                  <i className="fab fa-youtube ml-auto mr-auto" />
                </Button>
          </Col>
        <Col md="4">
        <h6>Sobre Nutricionconscienteuy</h6>
          <div>
          <a href="/auth/politicas-de-privacidad" target="_self"> Politicas de Privacidad</a>
          </div>
          <div>
          <a href="/auth/terminos-y-condiciones" target="_self"> Terminos y condiciones</a>
          </div>
          </Col>
          <Col md="4">
         {/*  <h6>Preguntas Frecuentes </h6>
          <div>
          <a href="/preguntas-frecuentes" target="_self"> Ver listado de preguntas</a>
          </div>
          <div>
          <a href="/hacer-pregunta" target="_self"> hacer una pregunta</a>
          </div> */}
          </Col>
        </Row>
      </Container>
      <Container fluid={this.props.fluid ? true : false}>
        <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "}
          <a href="https://appsuy.com" target="_blank">
            AppsUY
          </a>{" "}
          for a better web.
        </div>
      </Container>
    </footer>

    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
