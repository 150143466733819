import React from 'react';
import { connect } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import {getMensajes} from 'actions';

import classnames from 'classnames';
import Loader from 'assets/img/loadern.gif';

// reactstrap components
import {
    Container,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Table,
	UncontrolledTooltip,
} from 'reactstrap';

class Mensajes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			buscar: '',
			orderBy: 'desc',
		};
	}

	componentDidMount() {
		const {_id} = this.props.user
		document.body.classList.toggle('mensajes-component');
		this.props.getMensajes(_id);
	}
	componentWillUnmount() {
		document.body.classList.toggle('mensajes-component');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	verMensaje = (id) => {
		console.log(`Ver ${id}`);
	};

	removeMensaje = (id) => {
		console.log(`Borrar ${id}`);
    };


	listMensajes = () => {
		const { mensajes } = this.props;

	const filterByTextTitle = _.filter(mensajes, x =>
		this.reemplazarAcentos(x.title.toLowerCase()).includes(
			this.reemplazarAcentos(this.state.buscar.toLowerCase())
		)
	);

	const filterByTextDescription = _.filter(mensajes, x =>
		this.reemplazarAcentos(x.message.toLowerCase()).includes(
			this.reemplazarAcentos(this.state.buscar.toLowerCase())
		)
	);

	const filterByTextFrom = _.filter(mensajes, x =>
		this.reemplazarAcentos(x.from.toLowerCase()).includes(
			this.reemplazarAcentos(this.state.buscar.toLowerCase())
		)
	);

	const listFiltered = _.uniq((_.concat(filterByTextTitle, filterByTextDescription, filterByTextFrom)));


	const flOrder = this.state.orderByStateValue
			? _.orderBy(listFiltered, 'state','desc')
			: listFiltered;

	const lista = _.map(flOrder , (x)=>{
		return (
				<tr key={x._id}>
                <td className="text-center">
					<div className="photo">
						<img alt="..." src={x.avatar || "https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/defaultPerfil.png"} />
					</div>
				</td>
				<td>{x.name}</td>
				<td>{x.title} </td>
				<td className="text-center">{_.truncate(x.message, { length: 30, separator: ' ...' })}</td>
				<td className="text-right">{x.createAt}</td>
				<td className="text-right">
					<Button
						className="btn-link"
						color="warning"
						id="tooltip974171204"
						onClick={() => this.verPlan(x._id)}
						size="sm"
					>
						<i className="icon-line-mail" style={{fontSize:'20px'}}/>
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip974171204">
						Ver
					</UncontrolledTooltip>
					<Button
						className="btn-link"
						color="danger"
						id="tooltip974171201"
						onClick={() => this.removePlan(x._id)}
						size="sm"
					>
						<i className="tim-icons icon-simple-remove" style={{fontSize:'20px'}}/>
					</Button>
					<UncontrolledTooltip delay={0} target="tooltip974171201">
						Eliminar
					</UncontrolledTooltip>
				</td>
			</tr>
			)
		});
		return (
			<tbody>
			{lista}
			</tbody>
		);
	};

    change = (e) => {
		this.setState({buscar:e.target.value})
	}

	reemplazarAcentos = cadena => {
		const chars = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			à: 'a',
			è: 'e',
			ì: 'i',
			ò: 'o',
			ù: 'u',
			ñ: 'n',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U',
			À: 'A',
			È: 'E',
			Ì: 'I',
			Ò: 'O',
			Ù: 'U',
			Ñ: 'N',
		};
		const expr = /[áàéèíìóòúùñ]/gi;
		const res = cadena.replace(expr, e => {
			return chars[e];
		});
		return res;
	};

	render() {
		const { loading } = this.props;
		return (
			loading ? <div><img src={Loader} width="50px" alt=""/>loading...</div>
				:
				(
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>

				<div className="content">
                <Container>
					<Row>
						<Col md="12" className="ml-auto mr-auto text-left">
							<Button
								style={{ margin: '30px', width: '200px' }}
								className="btn-round"
								color="warning"
								onClick={() => console.log('#')}
								size="lg"
							>
							<i className="tim-icons icon-simple-add" /> {" "} Nuevo Mensaje
							</Button>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<div className="tools float-right">
										<InputGroup
											className={classnames(this.state.buscarState, {
												'input-group-focus': this.state.buscarFocus,
											})}
										>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="tim-icons icon-zoom-split" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												name="buscar"
												placeholder="Buscar..."
												type="text"
												onChange={(e) => this.change(e)}
												onFocus={(e) => this.setState({ buscarFocus: true })}
												onBlur={(e) => this.setState({ buscarFocus: false })}
											/>
										</InputGroup>
									</div>
									<CardTitle tag="h4" className="text-left">Listado Mensajes</CardTitle>
								</CardHeader>
								<CardBody>
									<Table responsive style={{ overflow: 'none' }}>
										<thead className="text-primary">
											<tr>
                                                <th className="text-center">#</th>
												<th className="text-center">Usuario</th>
												<th className="text-center">Titulo</th>
												<th className="text-center">Mensaje</th>
												<th className="text-right">Fecha</th>
												<th className="text-right">Acciones</th>
											</tr>
										</thead>
										{this.listMensajes()}
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
                    </Container>
				</div>
			</>
			)
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getMensajes: id => dispatch(getMensajes(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Mensajes);