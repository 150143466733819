import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { getRecetaById } from 'actions';
import YouTube from 'react-youtube';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  CardTitle, ListGroup
} from "reactstrap";

import ButtonMercadoPago from 'components/ButtonMercadoPago';

const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

class DetallesRecetas extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			receta: {
				title: '',
				description: '',
				type: '',
				file: '',
				items: '',
				avatar: '',
				costDolars: '',
				costPesos: '',
				linktoPayPaypal: '',
				linktoPayMercadoLibre: '',
				nota: '',
				video: '',
				videoPromo: '',
				premium: false,
				createdAt: '',
			},
			recetaData: [],
			imagenes: '',
			msg: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
		if (props.receta !== state.receta) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.receta !== this.props.receta) {
			this.setState({
				receta: this.props.receta,
				recetaData: this.props.receta,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.getRecetaById(id);
			return;
		}
    }
    
    
    handlerComprar (Link){
		window.location.assign(Link);
	};
	
	handlerGoBack() {
		this.props.goBack();
	}

  render() {
    const { user } = this.props;
    const { receta } = this.state;
    const token = user && user._id;
    const images = receta?.avatar.split(',');
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: 1,
        },
    };
    return (
      <>
        <div className="content">
          <Container>
		  <Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#57a97d'}}>Detalles Receta</h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto" md="9">
              <Card className="card-success">
              <CardBody>
				<CardTitle tag="h1" style={{ textAlign: 'center' }}>
					{receta.title}{' '}
				</CardTitle>
                {receta.videoPromo && receta.videoPromo.length > 3 &&
                    <YouTube videoId={receta.videoPromo} opts={opts} onReady={this._onReady} />
                }
                <ListGroup>
                <Carousel
											swipeable={true}
											draggable={true}
											responsive={responsive}
											ssr
											infinite={false}
											containerClass="first-carousel-container container"
											dotListClass="custom-dot-list-style"
										>
                    {images.map((image)=>(
                        <div>
                            <img alt={"imagen Control"} src={`https://d39etc8ensw3fs.cloudfront.net/fit-in/250x280/${image}`} width={'100%'} />
                        </div>
                        ))}
                    </Carousel>
                </ListGroup>
                
				<ListGroup>
				</ListGroup>
                <br/>
						<br/>
                <div dangerouslySetInnerHTML={{__html:receta.editorState}} />
						
						<br/>
						<br/>
						<br/>
				<div className="card-prices">
				<h2 className="text-on-front text-center">
						<span>$ </span>
						{ receta?.costPesos} <label> (pesos Uruguayos)</label>
					</h2>
				</div>
			</CardBody>
			<CardFooter className="text-center mb-1 mt-1">
				{token ? <ButtonMercadoPago productSelected={receta}  user={user} typeProduct={"receta"} />
				: (
					<>
						<p className='text-muted'>Para poder comprar necesitamos que te registres</p>
						<Button
							style={{ marginTop: '15px', width: '220px' }}
							className="btn-round"
                            color="warning"
							href="/auth/registrarme"
							size="lg"
						>
							Registrarse
						</Button>
					</>
				)}
			</CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
         
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
	  getRecetaById: (id) => dispatch(getRecetaById(id)),
	  goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallesRecetas);