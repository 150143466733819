import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";

class QuienSoy extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("quien-soy-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("quien-soy-page");
  }
  render() {
    return (
      <>
        <div className="content">
          <Container>
            <Row style={{marginBottom:"50px"}}>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h1 className="title" style={{color:'#57a97d'}}>Quien Soy</h1>
              </Col>
            </Row>
            <Row>
            <Col md="12">
              <Card className="card-testimonial">
                <CardHeader className="card-header-avatar">
                  <a href="#Matias" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img img-raised"
                      src={require("assets/img/cris.png")}
                    />
                  </a>
                </CardHeader>
                <CardBody>
                  <p className="card-description">Mi nombre es Cristian Silva, soy Licenciado en Nutrición, desde la  adolescencia me gusta mucho el deporte y con el objetivo de estar vinculado con él, decidí estudiar nutrición y dedicarme al área del deporte.<br />Es por eso que estoy continuamente realizando cursos sobre nutrición deportiva y diferentes temas relacionados.</p>
                  <p className="card-description">Actualmente realizo crossfit y mi objetivo es ayudar a personas que buscan mejorar su salud, rendimiento deportivo y/o composición corporal,y que presentan obesidad.</p>
                  <p className="card-description">¡Te espero para lograr tu mejor versión!</p>
                  <div className="icon icon-primary">
                    <i className="fa fa-quote-right" style={{color:"#56a97d"}} />
                  </div>
                </CardBody>
                <CardFooter>
                  <CardTitle tag="h4">Lic. Cristian Silva</CardTitle>
                  <p className="category">@nutricionconscienteuy</p>
                </CardFooter>
              </Card>
            </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default QuienSoy;