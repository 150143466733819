import React from "react";


// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

class Contacto extends React.Component {


  componentDidMount() {
    document.body.classList.toggle("quien-soy-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("quien-soy-page");
  }
  render() {
    return (
      <>
        <div className="content">
          <Container>
          <Row style={{marginBottom:"50px"}}>
              <Col md="12">
              </Col>
            </Row>
            <Row style={{marginBottom:"50px"}}>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h1 className="title" style={{color:'#57a97d'}}>Contacto</h1>
              </Col>
            </Row>
            <Row>

            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Contacto;
