import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { getPlanById } from 'actions';
import YouTube from 'react-youtube';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  CardTitle, ListGroup
} from "reactstrap";


import logoMercadoPago from "assets/img/logoMercadoPago.svg";

const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

class DetallesCompraPlan extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			plan: {
				userId: '',
				title: '',
				description: '',
				editorState:{},
                anamnesis:'',
				type: '',
				time: '',
				queries: '',
				typeQueries: '',
				file: '',
				items: '',
				costDolars: '',
				costPesos: '',
				linktoPayPaypal: '',
				linktoPayMercadoLibre: '',
				nota: '',
				video:'',
				premium:false,
				createdAt: '',
			},
			planData: [],
			imagenes: '',
			msg: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
		if (props.plan !== state.plan) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.plan !== this.props.plan) {
			this.setState({
				plan: this.props.plan,
				planData: this.props.plan,
			});
		}
	}

	componentDidMount() {
        const { idPlan, idUsuario } = this.props.match.params;
        const anamnesisCompleted = localStorage.getItem("anamnesisCompleted");
        const firstControlCompleted = localStorage.getItem("firstControlCompleted");
		if (idPlan) {
			this.props.getPlanById(idPlan);
			return;
        }
        if(anamnesisCompleted){
            localStorage.setItem('anamnesisCompleted', false);
        }
        if(firstControlCompleted){
            localStorage.setItem('firstControlCompleted', false);
        }
    }
    
    
    handlerComprar (Link){
		window.location.assign(Link);
	};
	
	handlerGoBack() {
		this.props.goBack();
	}

  render() {
    const { user } = this.props;
    const anamnesisCompleted = localStorage.getItem("anamnesisCompleted");
    const firstControlCompleted = localStorage.getItem("firstControlCompleted");
    return (
      <>
        <div className="content">
          <Container>
		  <Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								href="/auth/mis-compras"
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#57a97d'}}>Gracias por confiar en Nutrición Consciente Uy  </h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="9">
              <Card className="card-success">
              <CardBody>
				<CardTitle tag="h1" style={{ textAlign: 'center' }}>
					Haz comprado el Plan {this.state.plan.title}
				</CardTitle>
                {this.state.plan.video && this.state.plan.video.length > 3 &&
                    <YouTube videoId={this.state.plan.video} opts={opts} onReady={this._onReady} />
                }
				<ListGroup>
					<h3>{`El plan tiene una duración de ${this.state.plan.time} Meses`}</h3>
				</ListGroup>
                <div dangerouslySetInnerHTML={{__html:this.state.plan.editorState}} />
				<br />
				<br />
				<br />

			</CardBody>
                    <CardFooter className="text-center mb-3 mt-3">
                    <>
						<p className='text-muted'>Para poder empezar con el Plan {this.state.plan.title} necesitamos que completes los siguientes formularios</p>
						<Button
							style={{ marginTop: '15px', width: '320px', fontSize: '17px' }}
							className="btn-round"
                            color={anamnesisCompleted ? "success":"warning"}
                            disabled = {anamnesisCompleted}
							href={this.state.plan.anamnesis === 'Simple' ? `/auth/nueva-ficha-anamnesis-online/${user._id}` : `/auth/nueva-ficha-anamnesis/${user._id}`}
							size="lg"
						>
							Completar Anamnesis
                            {anamnesisCompleted && <i className="icon-ok-sign" style={{marginLeft:'10px', fontSize: '20px'}}/> }
						</Button>
                        <Button
							style={{ marginTop: '15px', width: '320px', fontSize: '17px' }}
							className="btn-round"
                            color={firstControlCompleted ? "success" : "warning"}
                            disabled = {firstControlCompleted}
							href={`/auth/nueva-ficha-control/${user._id}`}
							size="lg"
						>
							Completar Primer Control
                            {firstControlCompleted && <i className="icon-ok-sign" style={{marginLeft:'10px', fontSize: '20px'}} /> }
						</Button>

                        {anamnesisCompleted && firstControlCompleted &&
                            <Button
							style={{ marginTop: '15px', width: '320px', fontSize: '17px' }}
							className="btn-round"
                            color="success"
							href={`/auth/archivos/${this.state.plan._id}`}
							size="lg"
						>
							Descarga tus archivos <i className="icon-cloud-download-alt" style={{marginLeft:'10px', fontSize: '20px'}} />
						</Button>
                        }

					</>
                    </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
         
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
      mercadopago: (link) => dispatch(push(link)),
	  getPlanById: (id) => dispatch(getPlanById(id)),
	  goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallesCompraPlan);