import React from 'react';
import { connect } from 'react-redux';
import { updateEditedAnamnesis, getAnamnesisById, registrarAnamnesisNuevo } from 'actions';
import _ from 'lodash';
import Loader from 'assets/img/loadern.gif';
import { push, goBack } from 'react-router-redux';
import NotificationAlert from 'react-notification-alert';
import Select from 'react-select';

import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

const initialState = {
	anamnesis: {
		userId:'',
		workHours:'',
		daysWorks:'',
		typeFeedin:'',
		objectives:'',
		phisicsProblems:'',
		operations:'',
		diseases:'',
		pregnancies:'',
		medicines:'',
		medicalNotes:'',
		physicalActivity:'',
		sports:'',
		sportsHours:'',
		sportsDays:'',
        intolerancias:'',
        desayuno_lugar:'',
        desayuno_hora:'',
        desayuno_preparaciones:'',
        almuerzo_lugar:'',
        almuerzo_hora:'',
        almuerzo_preparaciones:'',
        merienda_lugar:'',
        merienda_hora:'',
        merienda_preparaciones:'',
        cena_lugar:'',
        cena_hora:'',
        cena_preparaciones:'',
        colaciones_lugar:'',
        colaciones_hora:'',
        colaciones_preparaciones:'',
        preferencias_lugar:'',
        preferencias_hora:'',
        preferencias_preparaciones:'',
        sabado_lugar:'',
        sabado_hora:'',
        sabado_preparaciones:'',
        domingo_lugar:'',
        domingo_hora:'',
        domingo_preparaciones:'',
        carne_vacuna_frecuencia:'',
        carne_vacuna_cantidad:'',
        pollo_frecuencia:'',
        pollo_cantidad:'',
        cerdo_frecuencia:'',
        cerdo_cantidad:'',
        pescado_frecuencia:'',
        pescado_cantidad:'',
        atun_frecuencia:'',
        atun_cantidad:'',
        huevo_frecuencia:'',
        huevo_cantidad:'',
        lacteos_frecuencia:'',
        lacteos_cantidad:'',
        verduras_frecuencia:'',
        verduras_cantidad:'',
        verduras_detalle:'',
        frutas_frecuencia:'',
        frutas_cantidad:'',
        frutas_detalle:'',
        panificados_frecuencia:'',
        panificados_cantidad:'',
        panificados_detalle:'',
        legumbres_frecuencia:'',
        legumbres_cantidad:'',
        legumbres_detalle:'',
        azucar_frecuencia:'',
        azucar_cantidad:'',
        azucar_detalle:'',
        caldo_frecuencia:'',
        caldo_cantidad:'',
        caldo_detalle:'',
        liquidos_frecuencia:'',
        liquidos_cantidad:'',
        liquidos_detalle:'',
        alimentos_procesados_frecuencia:'',
        alimentos_procesados_cantidad:'',
        alimentos_procesados_detalle:'',
        alcohol_frecuencia:'',
        alcohol_cantidad:'',
        alcohol_detalle:'',
        comida_fuera_frecuencia:'',
        comida_fuera_cantidad:'',
        comida_fuera_detalle:'',
	},
	anamnesisData:[],
    msg: '',
};

class FichaAnamnesisOnline extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anamnesis: {
				userId:'',
				workHours:'',
				daysWorks:'',
				typeFeedin:'',
				objectives:'',
				phisicsProblems:'',
				operations:'',
				diseases:'',
				pregnancies:'',
				medicines:'',
				medicalNotes:'',
				physicalActivity:'',
				sports:'',
				sportsHours:'',
				sportsDays:'',
                intolerancias:'',
                desayuno_lugar:'',
                desayuno_hora:'',
                desayuno_preparaciones:'',
                almuerzo_lugar:'',
                almuerzo_hora:'',
                almuerzo_preparaciones:'',
                merienda_lugar:'',
                merienda_hora:'',
                merienda_preparaciones:'',
                cena_lugar:'',
                cena_hora:'',
                cena_preparaciones:'',
                colaciones_lugar:'',
                colaciones_hora:'',
                colaciones_preparaciones:'',
                preferencias_lugar:'',
                preferencias_hora:'',
                preferencias_preparaciones:'',
                sabado_lugar:'',
                sabado_hora:'',
                sabado_preparaciones:'',
                domingo_lugar:'',
                domingo_hora:'',
                domingo_preparaciones:'',
                carne_vacuna_frecuencia:'',
                carne_vacuna_cantidad:'',
                pollo_frecuencia:'',
                pollo_cantidad:'',
                cerdo_frecuencia:'',
                cerdo_cantidad:'',
                pescado_frecuencia:'',
                pescado_cantidad:'',
                atun_frecuencia:'',
                atun_cantidad:'',
                huevos_frecuencia:'',
                huevos_cantidad:'',
                lacteos_frecuencia:'',
                lacteos_cantidad:'',
                verduras_frecuencia:'',
                verduras_cantidad:'',
                verduras_detalle:'',
                frutas_frecuencia:'',
                frutas_cantidad:'',
                frutas_detalle:'',
                panificados_frecuencia:'',
                panificados_cantidad:'',
                panificados_detalle:'',
                legumbres_frecuencia:'',
                legumbres_cantidad:'',
                legumbres_detalle:'',
                azucar_frecuencia:'',
                azucar_cantidad:'',
                azucar_detalle:'',
                caldo_frecuencia:'',
                caldo_cantidad:'',
                caldo_detalle:'',
                liquidos_frecuencia:'',
                liquidos_cantidad:'',
                liquidos_detalle:'',
                alimentos_procesados_frecuencia:'',
                alimentos_procesados_cantidad:'',
                alimentos_procesados_detalle:'',
                alcohol_frecuencia:'',
                alcohol_cantidad:'',
                alcohol_detalle:'',
                comida_fuera_frecuencia:'',
                comida_fuera_cantidad:'',
                comida_fuera_detalle:'',
			},
			anamnesisData:[],
			msg: '',
		};
	}

	componentWillUnmount() {
		document.body.classList.toggle('nuevo-anamnesis-component');
	}

	static getDerivedStateFromProps(props, state) {
		if (props.anamnesis !== state.anamnesis) {
			return {
			};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.anamnesis !== this.props.anamnesis) {
			this.setState({
				anamnesis:this.props.anamnesis,
				anamnesisData:this.props.anamnesis,
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
			if (id) {
				this.props.getAnamnesisById(id);
				return;
			}
	}

	handlerSelect = (value , type) => {
		const toChange = value.value;
		const { anamnesis } = this.state;
		anamnesis[type] = toChange;
		this.setState({...this.state, anamnesis });
	}

	handleInput = (event) => {
		const {anamnesis} = this.state;
		anamnesis[event.target.name] = event.target.value;
		this.setState({ anamnesis });
	};

	handlerSend = () => {
		const data = this.state.anamnesis;
		const idAnamnesis = this.state.anamnesis._id;
		const id = !_.isEmpty(this.props.usuario) ? this.props.usuario._id : this.props.match.params.id;

		const {workHours , daysWorks , typeFeedin, phisicsProblems , operations, diseases, pregnancies, medicines, medicalNotes, objectives, physicalActivity, sports, sportsHours, sportsDays} = this.state.anamnesis;
		const validator = workHours === ''
		|| daysWorks === ''
		|| typeFeedin === ''
		|| phisicsProblems === ''
		|| operations === ''
		|| diseases === ''
		|| pregnancies === ''
		|| medicines === ''
		|| medicalNotes === ''
		|| objectives === ''
		|| physicalActivity === ''
		|| sports === ''
		|| sportsHours === ''
		|| sportsDays === '' ? false : true;


		if(validator){
		if (idAnamnesis){
			localStorage.setItem('anamnesisCompleted', true);
			this.props.updateEditedAnamnesis(data, id);
			this.notify('br', "Se a guardo correctamente la Anamnesis");
		} else {
			data.userId = id;
			localStorage.setItem('anamnesisCompleted', true);
			this.props.registrarAnamnesisNuevo(data);
			this.notify('br', "Se a guardo correctamente la Anamnesis");
			}
		}else{
			this.notify('br', 'Por favor completa todos los campos ');
		}
	};

	notify = (place, message) => {
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>{message}</b>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
    };
    
    handlerGoBack() {
		this.props.goBack();
	}

	handlerNuevoControl() {
		console.log('#Control');
	}
	handlerEnviarMensaje() {
		console.log('#Mensaje');
	}

	handlerNuevoAnamnesis() {
		console.log('#Anamnesis');
	}

	render() {
        const { id: idUsuario } = this.props.match.params;
        const { anamnesis } = this.state;

		return this.props.loading || !anamnesis ? (
			<div style={{ width: '100%', textAlign: 'center' }}>
				<img src={Loader} width="50px" alt="" />
				loading...
			</div>
		) : (
			<>
			<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container>
                <Row className="ml-auto mr-auto text-left">
						<Col>
							<Button
								className="btn-round"
								color="warning"
								style={{ width: '100px', fontSize: '13px', float: 'left', paddingLeft: '20px' }}
								onClick={(e) => {
									this.handlerGoBack();
								}}
								size="lg"
							>
								<i className="icon-arrow-left2" /> Atras
							</Button>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
							<div>
								<h3 className="info-title" style={{ color: 'rgb(87, 169, 125)' }}>
									{idUsuario ? `Formulario Anamnesis Online` : 'Nueva ficha Anamnesis Online'}
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md="12">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Form className="form">
                                        <Row md="12">
										<Col className="mr-auto" md="6">
										<h4>Trabajo</h4>
										<h5>Agrega tu profesión/ tarea que realizas, horarios y días de trabajo y/o estudio.</h5>
										<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-clock text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Profesion / estudio .. Horas de trabajo"
															name="workHours"
															type="text"
															value={anamnesis.workHours}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-line2-calendar text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Días de trabajo"
															name="daysWorks"
															type="text"
															value={anamnesis.daysWorks}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h4>Indica que tipo de alimentación prefieres.</h4>
													<h5>–Vegano (sólo consume alimentos de origen vegetal)<br />
													– ovo- vegetariano (consume alimentos de origen vegetal y huevos) <br />
													– ovolacto-vegetariano (consume alimentos de origen vegetal huevo y lácteos) <br />
													– omnívoro (consume alimentos de origen animales y vegetal)</h5>
													
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="typeFeedin"
														value={{ value: anamnesis.typeFeedin, label: anamnesis.typeFeedin }}
														onChange={value => this.handlerSelect(value , "typeFeedin")}
														options={[
															{ value: 'Vegano', label: 'Vegano' },
															{ value: 'Ovo-vegetariano', label: 'Ovo-vegetariano' },
															{ value: 'Ovolacto-vegetariano', label: 'Ovolacto-vegetariano' },
															{ value: 'Omnívoro', label: 'Omnívoro' },
														]}
														placeholder="Tipo de alimentacion"
													/>
													<h4>Aspectos de salud</h4>
													<h5>Si presentas problemas físicos (musculares, articulares, hernia) o tienes antecedentes de cirugías descríbelos. Si no presentas, indica NO PRESENTO.</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-file-medical-alt text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Problemas Físicos musculares, articulares, hernia"
															name="phisicsProblems"
															type="text"
															value={anamnesis.phisicsProblems}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-notes-medical text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Operaciones/ cirugías reseñables"
															name="operations"
															type="text"
															value={anamnesis.operations}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h5>Enfermedades crónicas (diabetes, hipertensión, colesterol alto) </h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-file-medical text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Enfermedades, crónicas"
															name="diseases"
															type="text"
															value={anamnesis.diseases}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h5>Embarazo en la actualidad o en los últimos 3 meses </h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-female1 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Embarazos "
															name="pregnancies"
															type="text"
															value={anamnesis.pregnancies}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<h5>¿Estás tomando actualmente algún tipo de medicación?</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-eyedropper text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Medicamentos"
															name="medicines"
															type="text"
															value={anamnesis.medicines}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-medrt text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Notas Medicas"
															name="medicalNotes"
															type="text"
															value={anamnesis.medicalNotes}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
												</Col>
												<Col className="mr-auto" md="6">
												<h4>Objetivo o motivo de la consulta</h4>
												<h5>Bajar grasa corporal<br />
													Aumentar masa muscular<br />
													Alimentación saludable<br />
													Alimentación en deporte</h5>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="objetivos"
														value={{ value: anamnesis.objectives, label: anamnesis.objectives }}
														onChange={value => this.handlerSelect(value , "objectives")}
														options={[
															{ value: 'Bajar grasa corporal', label: 'Bajar grasa corporal' },
															{ value: 'Aumentar masa muscular', label: 'Aumentar masa muscular' },
															{ value: 'Alimentación saludable', label: 'Alimentación saludable' },
															{ value: 'Alimentación en deporte', label: 'Alimentación en deporte' },
														]}
														placeholder="Objetivos"
													/>


													<h4>Actividad física</h4>
													<h5>Tipo de actividad física que realizas, horario en cual lo practicas, tiempo que demora el entrenamiento y frecuencia de entrenamiento</h5>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-walking text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Actividad Fisica"
															name="physicalActivity"
															type="text"
															value={anamnesis.physicalActivity}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-futbol1 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Deportes"
															name="sports"
															type="text"
															value={anamnesis.sports}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-clock2 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Horario de entrenamiento"
															name="sportsHours"
															type="text"
															value={anamnesis.sportsHours}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
													
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i
																	className="icon-calendar21 text-success"
																	style={{ fontSize: '16px' }}
																/>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															placeholder="Dias de deporte"
															name="sportsDays"
															type="number"
															min='0'
															value={anamnesis.sportsDays}
															onChange={this.handleInput}
															style={{ height: '45px' }}
														/>
													</InputGroup>
										        </Col>
                                                </Row>
										</Form>
									</CardBody>
									<CardFooter style={{ textAlign: 'center' }}>
										<Button
											className="btn-round"
											color="warning"
											style={{ width: '300px' }}
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Guardar anamnesis
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEditedAnamnesis: (data, id) => dispatch(updateEditedAnamnesis(data, id)),
		getAnamnesisById: (id) => dispatch(getAnamnesisById(id)),
        registrarAnamnesisNuevo: (data) => dispatch(registrarAnamnesisNuevo(data)),
        goBack: () => dispatch(goBack()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FichaAnamnesisOnline);