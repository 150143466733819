import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import AgendaUsuarios from 'components/AgendaUsuarios';

import ButtonMercadoPago from 'components/ButtonMercadoPago';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

class TerminosyCondiciones extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("turnos-presenciales-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("turnos-presenciales-page");
  }
  render() {
 
    return (
      <>
        <div className="content">
          <Container>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#57a97d'}}>Términos y condiciones de contratación</h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Card className="card-success">
                    <CardBody className={"text-left"}>
                    <Col className="ml-auto mr-auto text-left" md="9">
                      <p> En el momento que el comprador completa el procedimiento de registro y compra algunos de los productos y/o servicios, acepta y se somete a las cláusulas del contrato, así como las condiciones de acceso y uso de la plataforma, política de privacidad, aviso legal y política de cookies.</p><br/>
                      <h6>Pagos y perfeccionamiento del contrato.</h6>
                      <p>Son aceptados para el pago de los productos y servicios de la presente web, los siguientes sistemas de pago:MERCADO PAGO (URUGUAY), PAYPAL.
El presente contrato quedará perfecto con el pago del producto y el envío al comprador de un correo electrónico de confirmación de compra.
Es necesario para la entrega del producto, el pago previo del mismo.<br/>
El comprador podrá solicitar el reembolso total del pago, siempre y cuando sea antes de la entrega del producto final. Una vez entregado no habrá reembolso, salvo por motivo de caso fortuito o de fuerza mayor ambos justificados inequívocamente.
</p><br/>
</Col>
                    </CardBody>
                    <CardFooter className="text-center mb-3 mt-3">

                    </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	  mercadopago: (link) => dispatch(push(link)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminosyCondiciones);