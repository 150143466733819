import { actions } from '../constants';

const initialState = {
	loading: false,
	msg: '',
	token: '',
	user: {},
	mensajes: {},
	mensajesEnviados: {},
	image: '',
	users: {},
	usuario: {},
	controles: {},
	calendar: {},
	control: {},
	tips: {},
	homeSlider: {},
	homeSliderMobile: {},
	resetas: {},
	reseta: {},
	tip: {},
};

const reducer = (state = initialState, action) => {
	console.log(action);
	switch (action.type) {
		case actions.REGISTRAR_USUARIO:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.REGISTRAR_USUARIO_NUEVO:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.REGISTRAR_USUARIO_NUEVO_SUCCESS:
			return {
				...state,
				loading: false,
				usuario: action.payload.data.user,
			};
		case actions.REGISTRAR_USUARIO_NUEVO_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.DELETE_USER:
			return {
				...state,
				loading: true,
			};
		case actions.DELETE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.message,
				users: action.payload.data.users,
			};
		case actions.DELETE_USER_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.REMOVE_CONTROL:
			return {
				...state,
				loading: true,
			};
		case actions.REMOVE_CONTROL_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
				calendar: action.payload.data.calendar,
			};
		case actions.REMOVE_CONTROL_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.DELETE_PLAN:
			return {
				...state,
				loading: true,
			};
		case actions.DELETE_PLAN_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.message,
				planes: action.payload.data.planes,
			};
		case actions.DELETE_PLAN_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.DELETE_TIP:
			return {
				...state,
				loading: true,
			};
		case actions.DELETE_TIP_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.message,
				tips: action.payload.data.tips,
			};
		case actions.DELETE_TIP_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.DELETE_RECETA:
			return {
				...state,
				loading: true,
			};
		case actions.DELETE_RECETA_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.message,
				recetas: action.payload.data.recetas,
			};
		case actions.DELETE_RECETA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.DELETE_CONTROL:
			return {
				...state,
				loading: true,
			};
		case actions.DELETE_CONTROL_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.message,
				controles: action.payload.data.controls,
			};
		case actions.DELETE_CONTROL_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.REGISTRAR_USUARIO_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.payload.data.token,
			};
		case actions.REGISTRAR_USUARIO_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_USERS:
			return {
				...state,
				loading: true,
			};
		case actions.GET_USER_BY_ID:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case actions.GET_USER_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				usuario: action.data,
				msg: action.data.message,
			};
		case actions.GET_USER_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data.message,
			};
		case actions.GET_CONTROLLS_BY_USER_ID:
			return {
				...state,
				loading: true,
				id: action.payload,
				control: {},
				controles: {},
			};
		case actions.GET_CONTROLLS_BY_USER_ID_SUCCESS:
			return {
				...state,
				loading: false,
				controles: action.payload,
			};
		case actions.GET_CONTROLLS_BY_USER_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: 'Tenemos algonos problemas para encontrar los controles del Usuario',
			};
		case actions.NEW_CONTROLL:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_CONTROLL_SUCCESS:
			return {
				...state,
				loading: false,
				control: action.payload.data.control,
				message: action.payload.data.message
			};
		case actions.NEW_CONTROLL_ERROR:
			return {
				...state,
				loading: false,
				message: action.payload,
			};

			case actions.ASSIGN_PLAN:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.ASSIGN_PLAN_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.ASSIGN_PLAN_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.NEW_CALENDAR:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_CALENDAR_SUCCESS:
			return {
				...state,
				loading: false,
				calendar: action.payload.data.calendar,
			};
		case actions.NEW_CALENDAR_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.UPDATE_CONTROLL:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_CONTROLL_SUCCESS:
			return {
				...state,
				loading: false,
				control: action.payload.control,
				message: action.payload.message
			};
		case actions.UPDATE_CONTROLL_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.UPDATE_CALENDAR:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_CALENDAR_SUCCESS:
			return {
				...state,
				loading: false,
				calendar: action.payload.calendar,
				msg: action.payload.message,
			};
		case actions.UPDATE_CALENDAR_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_CONTROLL_BY_ID:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case actions.GET_CONTROLL_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				control: action.data,
				msg: action.data.message,
			};
		case actions.GET_CONTROLL_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data.message,
			};
		case actions.GET_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				users: action.payload.data,
			};
		case actions.GET_USERS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.LOGIN:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.payload.data.token,
			};
		case actions.LOGIN_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.FORGOT_PASSWORD:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
			};
		case actions.FORGOT_PASSWORD_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.NEW_PASSWORD:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.NEW_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.msg,
			};
		case actions.NEW_PASSWORD_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.USER_DATA:
			return {
				...state,
				loading: true,
			};
		case actions.USER_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload.data.user,
			};
		case actions.USER_DATA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.UPDATE_USER:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				usuario: action.data.user,
				msg: action.data.message,
			};
		case actions.UPDATE_USER_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.SEND_MENSAJE:
			return {
				...state,
				loading: false,
				data: action.data,
				msg: '',
			};
		case actions.SEND_MENSAJE_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
			};
		case actions.SEND_MENSAJE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
			};
		case actions.GET_MENSAJES:
			return {
				...state,
				loading: true,
				id: action._id,
				msg: '',
			};
		case actions.GET_MENSAJES_SUCCESS:
			return {
				...state,
				loading: false,
				mensajes: action.payload.mensajes,
			};
		case actions.GET_MENSAJES_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.GET_MENSAJES_ENVIADOS:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GET_MENSAJES_ENVIADOS_SUCCESS:
			return {
				...state,
				loading: false,
				mensajesEnviados: action.payload.mensajesEnviados,
			};
		case actions.GET_MENSAJES_ENVIADOS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.CHANGE_STATUS_MENSAJE:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.CHANGE_STATUS_MENSAJE_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.msg,
			};
		case actions.CHANGE_STATUS_MENSAJE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.GET_RECETAS:
			return {
				...state,
				loading: true,
			};
		case actions.GET_RECETAS_SUCCESS:
			return {
				...state,
				loading: false,
				recetas: action.payload.data,
				receta: {},
			};
		case actions.GET_RECETAS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_PLANES:
			return {
				...state,
				loading: true,
				plan: {},
			};
		case actions.GET_PLANES_SUCCESS:
			return {
				...state,
				loading: false,
				planes: action.payload.data,
			};
		case actions.GET_PLANES_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_AGENDA:
			return {
				...state,
				loading: true,
			};
		case actions.GET_AGENDA_SUCCESS:
			return {
				...state,
				loading: false,
				calendar: action.payload.data,
			};
		case actions.GET_AGENDA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GO_EDIT_PROFILE:
			return {
				...state,
				usuario: action.payload,
			};
		case actions.CLEAR_USUARIO:
			return {
				...state,
				loading: false,
				usuario: {},
			};
		case actions.UPDATE_ANAMNESIS:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_ANAMNESIS_SUCCESS:
			return {
				...state,
				loading: false,
				anamnesis: action.data.anamnesis,
				msg: action.data.message,
			};
		case actions.UPDATE_ANAMNESIS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_ANAMNESIS_BY_ID:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case actions.GET_ANAMNESIS_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				anamnesis: action.data[0],
				msg: action.data.message,
			};
		case actions.GET_ANAMNESIS_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data.message,
			};
		case actions.REGISTRAR_ANAMNESIS_NUEVO:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.REGISTRAR_ANAMNESIS_NUEVO_SUCCESS:
			return {
				...state,
				loading: false,
				anamnesis: action.payload.data,
			};
		case actions.REGISTRAR_ANAMNESIS_NUEVO_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.NEW_PLAN:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_PLAN_SUCCESS:
			return {
				...state,
				loading: false,
				plan: action.payload.data,
			};
		case actions.NEW_PLAN_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_PLAN_BY_ID:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case actions.GET_PLAN_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				plan: action.data,
				msg: action.data.message,
			};
		case actions.GET_PLAN_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data.message,
			};

		case actions.UPDATE_PLAN:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_PLAN_SUCCESS:
			return {
				...state,
				loading: false,
				plan: action.data.plan,
				msg: action.data.message,
			};
		case actions.UPDATE_PLAN_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.NEW_RECETA:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_RECETA_SUCCESS:
			return {
				...state,
				loading: false,
				receta: action.payload.data.receta,
			};
		case actions.NEW_RECETA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_RECETA_BY_ID:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case actions.GET_RECETA_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				receta: action.data,
				msg: action.data.message,
			};
		case actions.GET_RECETA_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data.message,
			};
		case actions.UPDATE_RECETA:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_RECETA_SUCCESS:
			return {
				...state,
				loading: false,
				receta: action.data.receta,
				msg: action.data.message,
			};
		case actions.UPDATE_RECETA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		//------------------------
		case actions.UPDATE_TIP:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_TIP_SUCCESS:
			return {
				...state,
				loading: false,
				tip: action.data.tip,
				msg: action.data.message,
			};
		case actions.UPDATE_TIP_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.UPDATE_HOME_SLIDER:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_HOME_SLIDER_SUCCESS:
			return {
				...state,
				loading: false,
				homeSlider: [action.data.homeSlider],
			};
		case actions.UPDATE_HOME_SLIDER_ERROR:
			return {
				...state,
				loading: false,
			};

		case actions.UPDATE_HOME_SLIDER_MOBILE:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_HOME_SLIDER_MOBILE_SUCCESS:
			return {
				...state,
				loading: false,
				homeSliderMobile: [action.data.homeSliderMobile],
			};
		case actions.UPDATE_HOME_SLIDER_MOBILE_ERROR:
			return {
				...state,
				loading: false,
			};

		case actions.GET_TIP_BY_ID:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case actions.GET_TIP_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				tip: action.data,
				msg: action.data.message,
			};
		case actions.GET_TIP_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data.message,
			};
		case actions.NEW_TIP:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_TIP_SUCCESS:
			return {
				...state,
				loading: false,
				tip: action.payload.data.tip,
			};
		case actions.NEW_TIP_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.NEW_HOME_SLIDER:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_HOME_SLIDER_SUCCESS:
			return {
				...state,
				loading: false,
				homeSlider: action.payload.data.homeSlider,
			};
		case actions.NEW_HOME_SLIDER_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.NEW_HOME_SLIDER_MOBILE:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_HOME_SLIDER_MOBILE_SUCCESS:
			return {
				...state,
				loading: false,
				homeSliderMobile: action.payload.data.homeSliderMobile,
			};
		case actions.NEW_HOME_SLIDER_MOBILE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.GET_TIPS:
			return {
				...state,
				loading: true,
			};
		case actions.GET_TIPS_SUCCESS:
			return {
				...state,
				loading: false,
				tips: action.payload.data,
				tip: {},
			};
		case actions.GET_TIPS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.GET_HOME_SLIDER:
			return {
				...state,
				loading: true,
			};
		case actions.GET_HOME_SLIDER_SUCCESS:
			return {
				...state,
				loading: false,
				homeSlider: action.payload.data,
			};
		case actions.GET_HOME_SLIDER_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_HOME_SLIDER_MOBILE:
			return {
				...state,
				loading: true,
			};
		case actions.GET_HOME_SLIDER_MOBILE_SUCCESS:
			return {
				...state,
				loading: false,
				homeSliderMobile: action.payload.data,
			};
		case actions.GET_HOME_SLIDER_MOBILE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.NEW_FILE:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				file: action.payload.data.files,
			};
		case actions.NEW_FILE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_FILE_BY_ID:
			return {
				...state,
				loading: true,
				id: action.id,
			};
		case actions.GET_FILE_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				file: action.data[0],
				msg: action.data.message,
			};
		case actions.GET_FILE_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data.message,
			};

		case actions.UPDATE_FILE:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				file: action.data.files,
				msg: action.data.message,
			};
		case actions.UPDATE_FILE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_COMPRAS_BY_USER_ID:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.GET_COMPRAS_BY_USER_ID_SUCCESS:
			return {
				...state,
				loading: false,
				compras: action.data.compras,
			};
		case actions.GET_COMPRAS_BY_USER_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_VENTAS:
			return {
				...state,
				loading: true,
			};
		case actions.GET_VENTAS_SUCCESS:
			return {
				...state,
				loading: false,
				ventas: action.data.data,
			};
		case actions.GET_VENTAS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.NEW_MERCADO_PAGO_SELL:
			return {
				...state,
				loading: true,
				data: action.data,
			};
		case actions.NEW_MERCADO_PAGO_SELL_SUCCESS:
			return {
				...state,
				loading: false,
				compra: action.payload.data,
			};
		case actions.NEW_MERCADO_PAGO_SELL_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		default:
			return state;
	}
};

export default reducer;
