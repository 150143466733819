import React from "react";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import AgendaUsuarios from 'components/AgendaUsuarios';

import ButtonMercadoPago from 'components/ButtonMercadoPago';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

class TurnosPresenciales extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("turnos-presenciales-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("turnos-presenciales-page");
  }
  render() {
    const { user } = this.props;
    const token = user?._id;
    const control={
      title:'Turno Presencial',
      description:'Cuestionario y valoración nutricional mediante antropometría isak nivel 2. Plan nutricional totalmente personalizado a los objetivos de la persona.',
      type:'control',
      costPesos:1000,
      _id:'acsdffcontrol1231331123'
      };
    return (
      <>
        <div className="content">
          <Container>
            <Row style={{marginBottom:"10px"}}>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title" style={{color:'#57a97d'}}>Turnos Presenciales</h1>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Card className="card-success">
                    <CardBody className={"text-left"}>
                    <Col className="ml-auto mr-auto text-left" md="9">
                      <p> <b>- Cuestionario y valoración nutricional</b> mediante antropometría isak nivel 2.</p><br/>
                      <p> <b>- Plan nutricional totalmente personalizado</b> a los objetivos de la persona.</p><br/>
                      <p> <b>- Asesoramiento sobre nutrición en deportes,</b> disminución de grasa, aumento de masa muscular, obesidad, alimentación vegana, vegetariana, suplementación en caso de ser necesario.</p><br/>
                      <p> <b>- Un control gratis</b> con antropometría.</p><br/>
</Col>
                      <Col className="ml-auto mr-auto text-left" md="9">
            <h4 style={{color:'#57a97d'}}>Pasos para agendarte : </h4>
            <p  style={{color:'#57a97d'}}>
            1- Busca en el calendario un dia y una hora que te quede bien y que este disponible.</p>
            <br />
            <p style={{color:'#57a97d'}}>
            2- Has click en el boton de Mercado Pago y efectua el pago.
            </p>
            <br />
            <p style={{color:'#57a97d'}}>
            3- Mercado Pago te redireccionara y podras confirmar tu día y hora .
            tambien recibiras un email con la confirmacion y en el boton de mis compras podras ver
            tu compra que en este caso es un Turno presencial
            </p>
            </Col>
                    </CardBody>
                    <CardFooter className="text-center mb-3 mt-3">

                    {token ?
          <>
          <h4>Paga con Mercado Pago y agenda tu Control Presencial</h4>
          <ButtonMercadoPago productSelected={control}  user={user} typeProduct={"control"} />
          <br />
          <br />
          <AgendaUsuarios />
          </>
          :
          <>
          <p style={{color:'#57a97d'}} >Para poder agendarte necesitamos que te registres</p>
          <Button
                                  style={{ marginTop: '15px', width: '220px' }}
                                  className="btn-round"
                                  color="warning"
                                  href="/auth/registrarme"
                                  size="lg"
                              >
                                  Registrarme
                              </Button>
                              <AgendaUsuarios />
          </>}
                    </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
          </Row>
          </Container>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	  mercadopago: (link) => dispatch(push(link)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TurnosPresenciales);