import React from 'react';
import Seo from '../../components/Seo';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Zoom } from 'react-slideshow-image';
import { connect } from 'react-redux';
import { getPlanes, getRecetas, getHomeSlider, getHomeSliderMobile, getTips } from 'actions';
import { push } from 'react-router-redux';
import _ from 'lodash';

import Plan from 'components/Plan';
import Receta from 'components/Receta';
import Tip from 'components/Tip';
import {isMobile} from 'react-device-detect';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

const deviceType = 'mobile';
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 4,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 664, min: 0 },
		items: 1,
	},
};
const zoomInProperties = {
	indicators: true,
	scale: 1.4,
	width: '100%',
};

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			homeSlider: {
				title: '',
				description: '',
				avatar: '',
			},
			homeSliderMobile:{
				title: '',
				description: '',
				avatar: '',
			},
			tips: '',
			imagenes: '',
			msg: '',
			alert: null,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.homeSlider !== state.homeSlider ||props.homeSliderMobile !== state.homeSliderMobile  ) {
			return {};
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.homeSlider !== this.props.homeSlider) {
			this.setState({
				homeSlider: this.props.homeSlider[0],
				homeSliderData: this.props.homeSlider[0],
			});
		}
		if (prevProps.homeSliderMobile !== this.props.homeSliderMobile) {
			this.setState({
				homeSliderMobile: this.props.homeSliderMobile[0],
				homeSliderMobileData: this.props.homeSliderMobile[0],
			});
		}
	}

	componentDidMount() {
		document.body.classList.toggle('pricing-page');
		this.props.getPlanes();
		this.props.getRecetas();
		this.props.getHomeSlider();
		this.props.getHomeSliderMobile();
		this.props.getTips();
	}
	componentWillUnmount() {
		document.body.classList.toggle('pricing-page');
	}

	handlerVerDetallesControl = () => {
		console.log('verMas');
	};

	render() {
		const { user } = this.props;
		const { planes, recetas, tips } = this.props;
		const images = this.state?.homeSlider?.avatar !== '' ? _.split(this.state.homeSlider.avatar, ',') : [];
		const imagesMobile = this.state?.homeSliderMobile?.avatar !== '' ? _.split(this.state.homeSliderMobile.avatar, ',') : [];
		const links = _.split(this.state.homeSlider.links, ',')
		
		return (
			<>
				<Seo />
				<div className="content">
					<Container>
						<Row>
						{isMobile ? 
							<Col md="12">
								{!_.isEmpty(imagesMobile) && (
									<Zoom {...zoomInProperties}>
										{imagesMobile.map((image, index) => (
											<div className="each-slide">
												<img src= {`https://d39etc8ensw3fs.cloudfront.net/fit-in/600x700/${image}`} alt="" onClick={()=> window.location = links[index]}/>
											</div>
										))}
									</Zoom>
								)}
							</Col>
						
						:
							<Col md="12">
								{!_.isEmpty(images) && (
									<Zoom {...zoomInProperties}>
										{images.map((image, index) => (
											<div className="each-slide">
												<img src= {`https://d39etc8ensw3fs.cloudfront.net/fit-in/1280x360/${image}`} alt="" onClick={()=> window.location = links[index]}/>
											</div>
										))}
									</Zoom>
								)}
							</Col> }
						</Row>

						<Row style={{
							marginBottom: '50px',
							width: '100%',
							padding: '0px',
							margin: '0px'
							}}>
							<Col md="12">
								<h3
									className="info-title"
									style={{
										color: 'rgb(87, 169, 125)',
										marginBottom: '10px',
										marginTop: '40px',
										textAlign: 'center',
									}}
								>
									<b>PLANES</b>
								</h3>
								<h4
									className="info-title"
									style={{
										color: 'rgb(87, 169, 125)',
										marginBottom: '10px',
										marginTop: '10px',
										textAlign: 'center',
									}}
								>
									{' '}
									Adquiere uno de nuestros Planes online y descenso de Peso 100% personalizados
								</h4>
								<Carousel
									swipeable={true}
									draggable={false}
									responsive={responsive}
									ssr
									autoPlay
									autoPlaySpeed={4000}
									infinite={true}
									containerClass="first-carousel-container container"
									dotListClass="custom-dot-list-style"
								>
									{planes ? (
										planes?.map((plan) => (
											<div>
												<Plan user={user} planDetail={plan} />
											</div>
										))
									) : (
										<div />
									)}
								</Carousel>
							</Col>
						</Row>

						<Row
							style={{
							marginBottom: '50px',
							width: '100%',
							padding: '0px',
							margin: '0px'
							}}>
							<Col md="12">
								<h3
									className="info-title"
									style={{
										color: 'rgb(87, 169, 125)',
										marginBottom: '10px',
										marginTop: '40px',
										textAlign: 'center',
									}}
								>
									<b>RECETAS</b>
								</h3>
								<h4
									className="info-title"
									style={{
										color: 'rgb(87, 169, 125)',
										marginBottom: '10px',
										marginTop: '10px',
										textAlign: 'center',
									}}
								>
									Adquiere nuestros recetarios{' '}
								</h4>
							</Col>
							<Col md="12">
								<Carousel
									swipeable={true}
									draggable={false}
									responsive={responsive}
									ssr
									autoPlay
									autoPlaySpeed={4000}
									infinite={true}
									containerClass="first-carousel-container container"
									dotListClass="custom-dot-list-style"
								>
									{recetas ? (
										recetas?.map((receta) => (
											<div>
												<Receta user={user} recetaDetail={receta} />{' '}
											</div>
										))
									) : (
										<div />
									)}
								</Carousel>
							</Col>
						</Row>
						<Row
							style={{
								display: 'flex',
								justifyContent: 'center',
								paddingTop: '10px',
								marginLeft: 'auto',
								marginRight: 'auto',
								textAlign: 'center',
							}}
						>
							<Col md="12">
								<h3
									className="info-title"
									style={{
										color: 'rgb(87, 169, 125)',
										marginBottom: '10px',
										marginTop: '40px',
										textAlign: 'center',
									}}
								>
									<b>BLOG</b>
								</h3>
								<h4
									className="info-title"
									style={{
										color: 'rgb(87, 169, 125)',
										marginBottom: '10px',
										marginTop: '10px',
										textAlign: 'center',
									}}
								>
									{' '}
									Tips, Videos, Noticias, Aticulos Importantes
								</h4>
							</Col>
						</Row>

						<Row style={{ marginBottom: '50px' }}>
							<Col md="12">
								<Carousel
									swipeable={true}
									draggable={false}
									responsive={responsive}
									ssr
									autoPlay
									autoPlaySpeed={4000}
									infinite={true}
									containerClass="first-carousel-container container"
									deviceType={deviceType}
									dotListClass="custom-dot-list-style"
								>
									{!_.isEmpty(tips) ? (
										tips?.map((tip) => (
											<div>
												<Tip user={user} tipDetail={tip} />{' '}
											</div>
										))
									) : (
										<div />
									)}
								</Carousel>
							</Col>
						</Row>
					</Container>
				</div>{' '}
				<style jsx>{`
					.each-slide > div {
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: cover;
						height: 350px;
						width: 1150px;
					}

					.each-slide span {
						padding: 20px;
						font-size: 20px;
						background: #efefef;
						text-align: center;
					}
				`}</style>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		mercadopago: (link) => dispatch(push(link)),
		getPlanes: () => dispatch(getPlanes()),
		getRecetas: () => dispatch(getRecetas()),
		getHomeSlider: () => dispatch(getHomeSlider()),
		getHomeSliderMobile: () => dispatch(getHomeSliderMobile()),
		getTips: () => dispatch(getTips()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
