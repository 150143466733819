import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/';
import NotificationAlert from 'react-notification-alert';
import _ from 'lodash';

import classnames from 'classnames';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Label,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

class LoginMio extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			msg: '',
		};
	}

	componentDidMount() {
		document.body.classList.toggle('login-page');
	}
	componentWillUnmount() {
		document.body.classList.toggle('login-page');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
			if (_.includes(this.props.msg, 'Usuario')) {
				this.setState({
					msg: this.props.msg,
					emailState: 'has-danger',
					passwordState: 'has-danger',
				});
			} else if (_.includes(this.props.msg, 'Contraseña')) {
				this.setState({
					msg: this.props.msg,
					passwordState: 'has-danger',
				});
			}

			this.notify('br', this.state.msg);
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	verifyEmail = (value) => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};

	verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};

	verifyPasswordsData = (value) => {
		const content = value.length;

		if (content >= 8) {
			return true;
		}
		return false;
	};

	isValidated = () => {
		if (this.state.emailState === 'has-success' && this.state.passwordState === 'has-success') {
			return true;
		} else {
			if (this.state.emailState !== 'has-success') {
				this.notify('br', 'Email incorrecto');
				this.setState({ emailState: 'has-danger' });
			}
			if (this.state.passwordState !== 'has-success') {
				this.notify('br', 'Passwords incorrecto tiene que tener 8 caracteres minimo');
				this.setState({
					passwordState: 'has-danger',
					passwordConfirmState: 'has-danger',
				});
			}
			return false;
		}
	};

	change = (event, stateName, type, stateNameEqualTo, maxValue) => {
		switch (type) {
			case 'email':
				if (this.verifyEmail(event.target.value)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	};

	handlerSend = () => {
		if (this.isValidated()) {
			const data = { email: this.state.email, password: this.state.password };
			this.props.login(data);
		}
	};

	goToUrl = (url, target = '_self') => {
		window.open(url, target);
	};

	render() {
		return (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<div className="content">
					<Container>
						<Row style={{ marginBottom: '50px' }}>
							<Col className="ml-auto mr-auto text-center" md="6">
								<h1 className="title" style={{ color: '#57a97d' }}>
									Entrar a mi Cuenta
								</h1>
							</Col>
						</Row>
						<Row>
							<Col className="ml-auto mr-auto text-center" md="4">
								<Card className="card-register">
									<CardBody>
										<Form className="form">
											<InputGroup
												className={classnames(this.state.emailState, {
													'input-group-focus': this.state.emailFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-email-85" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="email"
													placeholder="Email..."
													type="email"
													onChange={(e) => this.change(e, 'email', 'email')}
													onFocus={(e) => this.setState({ emailFocus: true })}
													onBlur={(e) => this.setState({ emailFocus: false })}
												/>
											</InputGroup>
											<InputGroup
												className={classnames(this.state.passwordState, {
													'input-group-focus': this.state.passwordFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-lock-circle" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="password"
													placeholder="Contraseña..."
													type="password"
													onChange={(e) => this.change(e, 'password', 'length', 8)}
													onFocus={(e) => this.setState({ passwordFocus: true })}
													onBlur={(e) => this.setState({ passwordFocus: false })}
												/>
											</InputGroup>
											<FormGroup check className="text-left">
												<Label check>
													<Input type="checkbox" />
													<span
														className="form-check-sign"
														style={{ backgroundColor: '#57a97d' }}
													/>
													Recordar usuario{' '}
												</Label>
											</FormGroup>
											<div style={{ marginTop: '20px' }}>
												<a href="/auth/olvide-contrasena">
													No recuerdo la contraseña
												</a>
											</div>
										</Form>
									</CardBody>
									<CardFooter>
										<Button
											style={{ marginTop: '15px', width: '200px' }}
											className="btn-round"
											color="success"
											href="#Matias"
											onClick={(e) => {
												this.handlerSend();
											}}
											size="lg"
										>
											Entrar
										</Button>

										<Button
											style={{ marginTop: '15px', width: '200px' }}
											className="btn-round"
											color="success"
											href="/auth/registrarme"
											size="lg"
										>
											Registrarme
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (data) => dispatch(login(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMio);
