import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import  * as actions from "../../actions/";
import AdminNavBar from "./AdminNavbar";
import _ from 'lodash';

import logo from "assets/img/logo.png";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
		const token = localStorage.getItem('token');
		const { user } = this.props;

		if(_.isEmpty(user) && token){
			this.props.getUserData();
		}

  }
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-black";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  goToUrl = url => {
		window.open(url, '_blank');
  };

  navbarcomun  = () =>{
	return(
    <div className={classnames('navbar-absolute')}>
      <div style={{width:'100%', float:'right'}}>
						<NavLink to="/auth/entrar" className="nav-link" style={{float:"right"}}>
							<i className="icon-signin" /> Login Usuario
						</NavLink>
						<NavLink to="/auth/registrarme" className="nav-link" style={{float:"right"}}>
							<i className="icon-clipboard-check" /> Registrarme
						</NavLink>
					</div>
  <Navbar className={classnames( this.state.color)} expand="lg">
  <Container fluid style={{ marginTop: '0px' }}>
          <div className="navbar-wrapper">
            <NavbarBrand href="/" >
                <img alt="logo" src={logo} width="180px" style={{marginTop:"-30px"}}/>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>

          <Collapse isOpen={this.state.collapseOpen} onClick={this.state.collapseOpen ? this.toggleCollapse : undefined } navbar>
            <Nav navbar className="ml-auto">
            <NavItem>
                <NavLink to="/auth/home" className="nav-link" style={{color:'#57a97d',textAlign:'center'}}>
                  <i className="icon-home" style={{color:'#57a97d'}}/><br/> Home
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink to="/auth/quien-soy" className="nav-link" style={{color:'#57a97d', textAlign:'center'}}>
                  <i className="icon-address-card" style={{color:'#57a97d'}}/><br/> Quien soy?
                </NavLink>
              </NavItem>
               {/*<NavItem>
                <NavLink to="/auth/turnos-presenciales" className="nav-link" style={{color:'#57a97d', textAlign:'center'}}>
                  <i className="icon-calendar-alt" style={{color:'#57a97d'}}/><br/> Turnos presenciales
                </NavLink> 
              </NavItem>*/}
              <NavItem>
                <NavLink to="/auth/planes-a-distancia" className="nav-link" style={{color:'#57a97d', textAlign:'center'}}>
                  <i className="icon-clipboard-list" style={{color:'#57a97d'}}/><br/> Planes online
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/ebooks" className="nav-link" style={{color:'#57a97d', textAlign:'center'}}>
                  <i className="icon-book" style={{color:'#57a97d'}}/><br/> Ebooks
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      </div>
  );
	}

  showBar= () => {
	const { user } = this.props;
	const bar = _.isEmpty(user) ? this.navbarcomun() : <AdminNavBar user = {this.props.user}/>;
	return bar;
	}

  render() {
    return  this.showBar();
  }
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
		getUserData: ()=>{
			dispatch(actions.userData());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthNavbar);
