import VectorMap from "views/maps/VectorMap.jsx";
import GoogleMaps from "views/maps/GoogleMaps.jsx";
import FullScreenMap from "views/maps/FullScreenMap.jsx";
import ReactTables from "views/tables/ReactTables.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import Wizard from "views/forms/Wizard.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import RegularForms from "views/forms/RegularForms.jsx";
import Calendar from "views/Calendar.jsx";
import Widgets from "views/Widgets.jsx";
import Charts from "views/Charts.jsx";
import Dashboard from "views/Dashboard.jsx";
import Buttons from "views/components/Buttons.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Notifications from "views/components/Notifications.jsx";
import Grid from "views/components/Grid.jsx";
import Typography from "views/components/Typography.jsx";
import Panels from "views/components/Panels.jsx";
import Icons from "views/components/Icons.jsx";
import Pricing from "views/pages/Pricing.jsx";
import Register from "views/pages/Register.jsx";
import Timeline from "views/pages/Timeline.jsx";
import User from "views/pages/User.jsx";
import Login from "views/pages/Login.jsx";
import ForgotPassword from "views/pages/ForgotPassword.jsx";
import ChangePassword from "views/pages/ChangePassword.jsx";
import Rtl from "views/pages/Rtl.jsx";
import Lock from "views/pages/Lock.jsx";
import Home from "views/pages/Home.jsx";
import QuienSoy from "views/pages/QuienSoy.jsx";
import TurnosPresenciales from "views/pages/TurnosPresenciales.jsx";
import PlanesDistancia from "views/pages/PlanesDistancia.jsx";
import Administracion from "views/pages/Administracion.jsx";
import PlanPrincipiante from "views/pages/PlanPrincipiante.jsx";
import Contacto from "views/pages/Contacto.jsx";
import Registrarme from "views/pages/Registrarme.jsx";
import TerminosyCondiciones from "views/pages/TerminosyCondiciones.jsx";
import PoliticasPrivacidad from "views/pages/PoliticasPrivacidad.jsx";

import LoginMio from "views/pages/LoginMio.jsx";
import Mensajes from 'views/pages/Mensajes.jsx';
import EditUser from 'components/Admin/ListadoUsuarios/editUser.jsx';
import FichaAnamnesis from 'components/Admin/ListadoUsuarios/fichaAnamnesis.jsx';
import FichaAnamnesisOnline from 'components/Admin/ListadoUsuarios/FichaAnamnesisOnline.jsx';
import FichaControl from 'components/Admin/ListadoUsuarios/fichaControl.jsx';
import DetallesPlanes from 'components/DetallesPlanes';
import DetallesRecetas from 'components/DetallesRecetas';
import DetallesControles from 'components/DetallesControles';
import DetallesTips from 'components/DetallesTips';
import FichaControlPresencial from 'components/Admin/ListadoUsuarios/fichaControlPresencial.jsx';
import Controles from 'components/Admin/ListadoUsuarios/Controles.jsx';

import MisCompras from "views/pages/MisCompras.jsx";

import Planes from 'components/Admin/ListadoUsuarios/planes.jsx';

import Recetas from 'components/Admin/ListadoUsuarios/recetas.jsx';
import FichaTip from 'components/Admin/ListadoUsuarios/FichaTip.jsx';
import FileUpload from 'components/Admin/FileUpload';
import DetallesCompraPlan from 'components/DetallesCompraPlan';
import DetallesCompraReceta from 'components/DetallesCompraReceta';
import DescargarArchivosProducto from 'components/DescargarArchivosProducto';
import AgendarControlUsuario from 'components/AgendarControlUsuario';




const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        rtlName: "عالتسعير",
        mini: "P",
        rtlMini: "ع",
        component: Pricing,
        layout: "/auth"
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: Rtl,
        layout: "/rtl"
      },
      {
        path: "/timeline",
        name: "Timeline",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: Timeline,
        layout: "/admin"
      },
      {
        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/lock-screen",
        name: "Lock Screen",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: Lock,
        layout: "/auth"
      },
      {
        path: "/user-profile",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: User,
        layout: "/admin"
      },
      {
        path: "/home",
        name: "Home",
        mini: "UP",
        rtlMini: "",
        component: Home,
        layout: "/auth"
      },
      {
        path: "/quien-soy",
        name: "QuienSoy",
        mini: "UP",
        rtlMini: "",
        component: QuienSoy,
        layout: "/auth"
      },
      {
        path: "/turnos-presenciales",
        name: "TurnosPresenciales",
        mini: "UP",
        rtlMini: "",
        component: TurnosPresenciales,
        layout: "/auth"
      },
      {
        path: "/planes-a-distancia",
        name: "PlanesDistancia",
        mini: "UP",
        rtlMini: "",
        component: PlanesDistancia,
        layout: "/auth"
      },
      {
        path: "/ebooks",
        name: "PlanPrincipiante",
        mini: "UP",
        rtlMini: "",
        component: PlanPrincipiante,
        layout: "/auth"
      },
      {
        path: "/contacto",
        name: "Contacto",
        mini: "UP",
        rtlMini: "",
        component: Contacto,
        layout: "/auth"
      },
      {
        path: "/registrarme",
        name: "Registrarme",
        mini: "UP",
        rtlMini: "",
        component: Registrarme,
        layout: "/auth"
      },
      {
        path: "/olvide-contrasena",
        name: "Forgot password",
        mini: "UP",
        rtlMini: "",
        component: ForgotPassword,
        layout: "/auth"
      },
      {
        path: "/resetPassword/user/:token/",
        name: "Cambiar password",
        mini: "UP",
        rtlMini: "",
        component: ChangePassword,
        layout: "/auth"
      },
      {
        path: "/entrar",
        name: "LoginMio",
        mini: "UP",
        rtlMini: "",
        component: LoginMio,
        layout: "/auth"
      },
      {
        path: "/administracion",
        name: "Administracion",
        mini: "UP",
        rtlMini: "",
        component: Administracion,
        layout: "/auth"
      },
      {
        path: "/mensajes",
        name: "Mensajes",
        mini: "UP",
        rtlMini: "",
        component: Mensajes,
        layout: "/auth"
      },
      {
        path: "/usuario/:id",
        name: "Perfil Usuario",
        mini: "UP",
        rtlMini: "",
        component: EditUser,
        layout: "/auth"
      },
      {
        path: "/controles/:id",
        name: "Controles Usuario",
        mini: "UP",
        rtlMini: "",
        component: Controles,
        layout: "/auth"
      },
      {
        path: "/nuevo-usuario/",
        name: "Nuevo usuario",
        mini: "UP",
        rtlMini: "",
        component: EditUser,
        layout: "/auth"
      },
      {
        path: "/mis-compras/:id",
        name: "Mis Compras",
        mini: "UP",
        rtlMini: "",
        component: MisCompras,
        layout: "/auth"
      },
      {
        path: "/nueva-ficha-anamnesis/:id",
        name: "Nueva ficha Anamnesis",
        mini: "UP",
        rtlMini: "",
        component: FichaAnamnesis,
        layout: "/auth"
      },
      {
        path: "/nueva-ficha-anamnesis-online/:id",
        name: "Nueva ficha Anamnesis Online",
        mini: "UP",
        rtlMini: "",
        component: FichaAnamnesisOnline,
        layout: "/auth"
      },
      {
        path: "/anamnesis/:idAnamnesis/",
        name: "Nueva ficha Anamnesis",
        mini: "UP",
        rtlMini: "",
        component: FichaAnamnesis,
        layout: "/auth"
      },
      {
        path: "/nueva-ficha-control/:idUsuario",
        name: "Nueva ficha Control",
        mini: "UP",
        rtlMini: "",
        component: FichaControl,
        layout: "/auth"
      },
      {
        path: "/nueva-ficha-control-presencial/:idUsuario",
        name: "Control Presencial",
        mini: "UP",
        rtlMini: "",
        component: FichaControlPresencial,
        layout: "/auth"
      },
      {
        path: "/nuevo-plan",
        name: "Nuevo plan",
        mini: "UP",
        rtlMini: "",
        component: Planes,
        layout: "/auth"
      },
      {
        path: "/nueva-receta",
        name: "Nuevo receta",
        mini: "UP",
        rtlMini: "",
        component: Recetas,
        layout: "/auth"
      },
      {
        path: "/nuevo-tip",
        name: "Nuevo receta",
        mini: "UP",
        rtlMini: "",
        component: FichaTip,
        layout: "/auth"
      },
      {
        path: "/control/:idUsuario/",
        name: "Nueva ficha Control",
        mini: "UP",
        rtlMini: "",
        component: FichaControl,
        layout: "/auth"
      },
      {
        path: "/plan/:id/",
        name: "Editar Plan",
        mini: "UP",
        rtlMini: "",
        component: Planes,
        layout: "/auth"
      },
      {
        path: "/receta/:id/",
        name: "Editar Receta",
        mini: "UP",
        rtlMini: "",
        component: Recetas,
        layout: "/auth"
      },
      {
        path: "/tip/:id/",
        name: "Editar Tip",
        mini: "UP",
        rtlMini: "",
        component: FichaTip,
        layout: "/auth"
      },
      {
        path: "/producto/:id/",
        name: "agregar file al producto ",
        mini: "UP",
        rtlMini: "",
        component: FileUpload,
        layout: "/auth"
      },
      {
        path: "/planes/:id/",
        name: "Detalle Plan",
        mini: "UP",
        rtlMini: "",
        component: DetallesPlanes,
        layout: "/auth"
      },
      {
        path: "/tip-detalle/:id/",
        name: "Detalle Tip",
        mini: "UP",
        rtlMini: "",
        component: DetallesTips,
        layout: "/auth"
      },
      {
        path: "/recetas/:id/",
        name: "Detalle Receta",
        mini: "UP",
        rtlMini: "",
        component: DetallesRecetas,
        layout: "/auth"
      },
      {
        path: "/editar-ficha-control/:idUsuario/:idControl",
        name: "Editar ficha Control",
        mini: "UP",
        rtlMini: "",
        component: FichaControl,
        layout: "/auth"
      },
      {
        path: "/editar-ficha-control-offline/:idUsuario/:idControl",
        name: "Editar ficha Control offline",
        mini: "UP",
        rtlMini: "",
        component: FichaControlPresencial,
        layout: "/auth"
      },
      {
        path: "/detalles-controles/:id/",
        name: "Detalle Controles",
        mini: "UP",
        rtlMini: "",
        component: DetallesControles,
        layout: "/auth"
      },
      {
        path: "/compra-plan/:idPlan/:idUsuario",
        name: "Detalle Compra Plan",
        mini: "UP",
        rtlMini: "",
        component: DetallesCompraPlan,
        layout: "/auth"
      },
      {
        path: "/compra-receta/:idReceta/:idUsuario",
        name: "Detalle Compra Receta",
        mini: "UP",
        rtlMini: "",
        component: DetallesCompraReceta,
        layout: "/auth"
      },
      {
        path: "/agendar-control/:idUsuario",
        name: "Detalle Compra Turno presencial",
        mini: "UP",
        rtlMini: "",
        component: AgendarControlUsuario,
        layout: "/auth"
      },
      {
        path: "/archivos/:id",
        name: "Descargar archivos",
        mini: "UP",
        rtlMini: "",
        component: DescargarArchivosProducto,
        layout: "/auth"
      },
      {
        path: "/terminos-y-condiciones",
        name: "Terminos y condiciones ",
        mini: "UP",
        rtlMini: "",
        component: TerminosyCondiciones,
        layout: "/auth"
      },
      {
        path: "/politicas-de-privacidad",
        name: "Politicas de Privacidad ",
        mini: "UP",
        rtlMini: "",
        component: PoliticasPrivacidad,
        layout: "/auth"
      },


    ]
  },
  {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: "tim-icons icon-molecule-40",
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MLT",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/admin"
          }
        ]
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: Grid,
        layout: "/admin"
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin"
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "tim-icons icon-notes",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: "tim-icons icon-puzzle-10",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin"
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin"
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin"
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: "tim-icons icon-settings",
    component: Widgets,
    layout: "/admin"
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: "tim-icons icon-chart-bar-32",
    component: Charts,
    layout: "/admin"
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: "tim-icons icon-time-alarm",
    component: Calendar,
    layout: "/admin"
  }
];

export default routes;
